import React from "react";
import { StyledBurger } from './Burger.styled';

export const Burger = ({open}) => {
  return (
    <StyledBurger open={open}>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </StyledBurger>
  );
};