import React from "react";

export const RotateIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M4.72450027,9.49473246 C5.74499969,6.32523249 8.66999969,4.20023249 12,4.20023249 C13.8979996,4.20023249 15.65,4.90923212 17.0100006,6.09023188 L14.7550003,8.30023249 C13.9550003,9.14023264 14.5399994,10.5702322 15.7199997,10.6002325 L23.0649994,10.6002325 C23.5850006,10.5852323 24,10.1652326 24,9.64523219 L24,2.35223292 C24,1.149732 22.5449997,0.547733101 21.6949997,1.39823206 L19.2699997,3.824732 C17.325,2.05973261 14.7699997,1.00023249 12,1.00023249 C7.275,1.00023249 3.12949982,4.01723231 1.67900009,8.5102331 C1.40709991,9.35073222 1.86884995,10.2542326 2.70950012,10.5242323 C3.55800018,10.7992323 4.45250015,10.3336325 4.72450027,9.49473246 Z M2.30350037,23.0037329 L4.72850037,20.5772329 C6.67500076,22.3402319 9.23000107,23.4002325 11.9550011,23.4002325 C16.6775002,23.4002325 20.8250008,20.3832327 22.2750008,15.8902319 C22.5950005,15.0502325 22.1300011,14.1052328 21.2900002,13.8802328 C20.4429993,13.6083334 19.5459991,14.0708334 19.275,14.9097326 C18.2550003,18.0752325 15.3300003,20.2002325 12,20.2002325 C10.1020004,20.2002325 8.35,19.4912329 6.99000015,18.3102331 L9.2,16.1002325 C10.0449997,15.2602331 9.45999985,13.8302328 8.28000031,13.8002325 L0.933000183,13.8002325 C0.416000366,13.8152319 0,14.2352331 0,14.7552328 L0,22.0502325 C0,23.2522329 1.45350037,23.8542333 2.30350037,23.0037329 Z"></path>
      </svg>
    </>
  );
};
