import React from "react";

export const DeezerIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M22,7.5232633 L22,5 L17.6756941,5 L17.6756941,7.5232633 L22,7.5232633 Z M22,11.0378472 L22,8.51319419 L17.6756941,8.51319419 L17.6756941,11.0378472 L22,11.0378472 Z M11.5486111,11.0378472 L11.5486111,8.51319419 L7.22465282,8.51319419 L7.22499985,11.0378472 L11.5486111,11.0378472 Z M22,14.5506944 L22,12.0270835 L17.6756941,12.0270835 L17.6756941,14.5506944 L22,14.5506944 Z M16.7753472,14.5506944 L16.7753472,12.0270835 L12.4513889,12.0270835 L12.4513889,14.5506944 L16.7753472,14.5506944 Z M11.5486111,14.5506944 L11.5486111,12.0270835 L7.22465282,12.0270835 L7.22499985,14.5506944 L11.5486111,14.5506944 Z M6.32430585,18.0649302 L6.32430585,15.5416669 L2,15.5416669 L2,18.0649302 L6.32430585,18.0649302 Z M11.5486111,18.0649302 L11.5486111,15.5416669 L7.22465282,15.5416669 L7.22465282,18.0649302 L11.5486111,18.0649302 Z M16.7753472,18.0649302 L16.7753472,15.5416669 L12.4513889,15.5416669 L12.4510413,18.0649302 L16.7753472,18.0649302 Z M22,18.0649302 L22,15.5416669 L17.6756941,15.5416669 L17.6756941,18.0649302 L22,18.0649302 Z"></path>
      </svg>
    </>
  );
};

