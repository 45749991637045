import React from "react";

export const DashboardIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M5.00020754,22.3342188 C5.05020949,22.3342188 5.10021207,22.3300517 5.15021402,22.3258846 C5.21271661,22.3300517 5.27521921,22.3342188 5.33772244,22.3342188 L5.33772244,22.3342188 L7.66698553,22.3342188 C8.58785734,22.3342188 9.33372138,21.5883548 9.33372138,20.667483 L9.33372138,20.667483 L9.33372138,17.0006641 C9.33372138,16.263133 9.92957957,15.6672754 10.6671101,15.6672754 L10.6671101,15.6672754 L13.3338874,15.6672754 C14.0714185,15.6672754 14.6672761,16.263133 14.6672761,17.0006641 L14.6672761,17.0006641 L14.6672761,20.667483 C14.6672761,21.5883548 15.4131401,22.3342188 16.3340119,22.3342188 L16.3340119,22.3342188 L18.6882763,22.3342188 C18.7466118,22.3342188 18.8049473,22.2967175 18.8632841,22.3300517 C18.9091183,22.2967175 18.9549538,22.3342188 19.0007893,22.3342188 L19.0007893,22.3342188 L19.6674836,22.3342188 C20.5883554,22.3342188 21.3342195,21.5883548 21.3342195,20.667483 L21.3342195,20.667483 L21.3342195,19.9924545 C21.3467195,19.8799506 21.3550537,19.7716125 21.3550537,19.6549415 L21.3550537,19.6549415 L21.3258853,12.983831 L22.659274,12.983831 C23.3676367,12.983831 23.9926626,12.3963064 23.9926626,11.6462752 C24.0384981,11.2712597 23.8676562,10.9379125 23.534309,10.6462337 L23.534309,10.6462337 L12.896368,1.29226245 C12.6421905,1.08350337 12.3088433,1 12.0171646,1 C11.7254858,1 11.3921386,1.04175232 11.1004598,1.3340135 L11.1004598,1.3340135 L0.417100417,10.6462337 C0.125171771,10.9379125 0,11.2712597 0,11.6462752 C0,12.3963064 0.584191092,12.983831 1.33547222,12.983831 L1.33547222,12.983831 L2.67052736,12.983831 L2.67052736,15.8839516 C2.66802736,15.9214529 2.66677735,15.9631213 2.66677735,16.0006226 L2.66677735,16.0006226 L2.66677735,20.667483 C2.66677735,21.5883548 3.41305848,22.3342188 4.3335132,22.3342188 L4.3335132,22.3342188 L5.00020754,22.3342188 Z"></path>
      </svg>
    </>
  );
};
