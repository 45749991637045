import LinkStyled from "@/components/LinkStyled";
import { MenuItem } from "@mui/material";
import { useRouter } from "next/router";

export const NavigationDesktopMenuItem = ({ label, href, onClick }) => {
  const router = useRouter();

  const handleClick = (event) => {
    if (onClick) {
      return onClick(event);
    }
  };

  return (
    <MenuItem onClick={handleClick} disableRipple>
      <LinkStyled
        href={href}
        label={label}
        variant={`navlink${
          router.pathname.endsWith(href) ? "__is-active" : ""
        }`}
      />
    </MenuItem>
  );
};
