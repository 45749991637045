import React from "react";

function AgbIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M15 10.5c0 .469-.219.844-.5 1.156v2.531c.281.188.5.5.5.844 0 .531-.469.969-1 .969H4c-1.688 0-3-1.313-3-3V3c0-1.656 1.313-3 3-3h9.5c.813 0 1.5.688 1.5 1.5v9zM8.344 7.625c1.187.281 1.437.469 1.375.844-.063.437-.75.625-1.625.5-.313-.031-.688-.156-1.031-.281l-.188-.063a.52.52 0 00-.625.344.52.52 0 00.344.625l.156.062c.375.125.813.25 1.219.313.218.031.437.031.625.031 1.156 0 1.937-.469 2.125-1.313.125-.687-.156-1.093-.531-1.406.28-.218.437-.5.53-.843.25-1.438-1.155-1.782-2.124-2.032l-.219-.062C7.25 4.03 7.187 3.812 7.25 3.53c.063-.406.75-.625 1.625-.5.188.031.438.094.781.219a.52.52 0 00.625-.344.52.52 0 00-.344-.625A4.863 4.863 0 009 2.062c-1.469-.218-2.531.282-2.719 1.282-.156.656.125 1.094.5 1.375a1.71 1.71 0 00-.531.875C6 7 7.438 7.375 8.125 7.562l.219.063zM13 14v-2H4c-.563 0-1 .469-1 1 0 .563.438 1 1 1h9zM8.344 5.375c1.187.281 1.437.469 1.375.844-.063.375-.5.468-.781.531-.126-.031-.563-.156-.563-.156C7.25 6.28 7.187 6.062 7.25 5.78c.063-.343.5-.468.781-.5.031 0 .313.094.313.094z"
      ></path>
    </svg>
  );
}

export default React.memo(AgbIcon);
