import theme from "@/styles/theme";
import { Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";

export default function TextFieldStyled({
  id,
  required = false,
  autoFocus = false,
  hint,
  name,
  type,
  label,
  labelhint,
  value,
  defaultValue,
  placeholder,
  onChange,
  onBlur,
  onKeyPress,
  helpertext,
  multiline,
  startAdornment,
  errorText,
  readonly = false,
  maxLength = 255,
  endAdornment,
  numeric = false,
}) {
  const [valueOfField, setValueOfField] = useState(value ?? defaultValue); // we won't break (un-)controlled state
  const [isValid, setIsValid] = useState(value ?? defaultValue ?? !required);

  useEffect(() => {
    if (!required) {
      setIsValid(true);
    } else {
      setIsValid(defaultValue ?? value ?? false);
    }
  }, [required]);

  // Check validation on valueOfField changed
  useEffect(() => {
    if (required) {
      setIsValid(valueOfField || false);
    } else {
      setIsValid(true);
    }
  }, [valueOfField]);

  const handleInputChanged = (event) => {
    const v = event.target.value;
    setValueOfField(v);

    if (onChange) {
      onChange(event);
    }
  };

  const handleKeyPress = (event) => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  const handleBlur = (event) => {
    if (onBlur && typeof onBlur === "function") {
      onBlur(event);
    }
  };

  return (
    <>
      <InputLabel
        htmlFor={name}
        sx={{
          color: isValid
            ? theme.palette.common.black
            : theme.palette.error.main,
        }}
      >
        {label}
        {required && "*"} {labelhint && <span>({labelhint})</span>}
        <Typography
          variant="caption"
          component="span"
          sx={{
            "&.MuiTypography-root": {
              color: isValid ? grey[600] : theme.palette.error.main,
            },
          }}
        >
          {hint}
        </Typography>
      </InputLabel>
      <TextField
        id={id ?? name}
        autoFocus={autoFocus}
        fullWidth
        hiddenLabel
        required={required}
        name={name}
        type={type}
        placeholder={placeholder}
        variant="filled"
        value={value}
        defaultValue={defaultValue}
        onChange={handleInputChanged}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        helperText={errorText ?? helpertext}
        multiline={multiline}
        error={errorText}
        sx={{
          "&.MuiFormControl-root": {
            background: "transparent",
          },
          "& .MuiFilledInput-root": {
            "&::before": {
              borderColor:
                !isValid || errorText?.length > 0
                  ? "#c33132 !important"
                  : "transparent",
            },
          },
        }}
        inputProps={{
          maxLength: multiline && maxLength === 255 ? undefined : maxLength,
          inputMode: numeric && "numeric",
          pattern: numeric ? "[0-9]*" : null,
        }}
        InputProps={{
          readOnly: readonly,
          className: readonly ? "Mui-disabled" : undefined,
          startAdornment: startAdornment && (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: endAdornment && (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
      />
    </>
  );
}
