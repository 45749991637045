import Image from "next/legacy/image";
import Logo from "../../public/img/showcase-nrw-logo-white.svg";
import React from "react";

export const ShowcaseLogo = ({ ...props }) => {
  return (
    <>
      <Image layout="fixed" src={Logo} alt="showcase.nrw" />
    </>
  );
};
