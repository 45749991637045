export const ChevronDownIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentcolor"
        d="M23.576 8.311l-10.604 10.16c-.333.279-.667.39-.944.39-.333 0-.667-.111-.944-.334L.424 8.312a1.325 1.325 0 01-.056-1.887 1.325 1.325 0 011.888-.056l9.772 9.328 9.716-9.328c.5-.5 1.388-.5 1.888.056.5.5.5 1.388-.056 1.887z"
      ></path>
    </svg>
  );
};
