import React from "react";
import Image from "next/legacy/image";

export const ImageAsset = ({ url, width, height, priority = false, alt, placeholder = null, blurDataURL = "LCC%2Jt200V~?bt9RoRi00WG~qxv", ...props }) => {
  if (!url) return <></>;

  if (!url.startsWith("http") && !props.local) {
    url = `${process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337"}${url}`;
  }

  const layoutProp = props.layout || "intrinsic";

  return (
    <Image
      src={url}
      width={width}
      height={height}
      objectFit="cover"
      layout={layoutProp}
      placeholder={placeholder}
      blurDataURL={blurDataURL}
      quality={78}
      priority={priority}
      alt={alt}
    />
  );
};
