import React from "react";

export const BandcampIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path
          d="M12,22 C17.5241935,22 22,17.5241935 22,12 C22,6.47580645 17.5241935,2 12,2 C6.47580645,2 2,6.47580645 2,12 C2,17.5241935 6.47580645,22 12,22 Z M13.9435489,15.1491938 L6.64516178,15.1491938 L10.0604836,8.85483871 L17.3588707,8.85483871 L13.9435489,15.1491938 Z"
        ></path>
      </svg>
    </>
  );
};

