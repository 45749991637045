import CookieConsentContext from "./CookieConsentContext";
import { useContext, useEffect, useState } from "react";
import * as React from "react";
import AppContext from "@/context/AppContext";
import dynamic from "next/dynamic";

const CookieConsentDialog = dynamic(() => import('./CookieConsentDialog'));

const COOKIES = [
    {
        name: 'Technisch notwendig',
        readonly: true,
        status: true,
    },
    {
        name: 'Funktionell',
        status: false,
        options: [
            {
                name: 'Mapbox',
                status: false
            },
            {
                name: 'Soundcloud',
                status: false
            }
        ]
    },
    {
        name: 'Marketing',
        status: false,
        options: [
            {
                name: 'Analytics',
                status: false
            },
        ]
    }
];

export default function CookieConsentProvider({children}) {
    const {displayCookieConsent, setDisplayCookieConsent} = useContext(AppContext);
    const [presented, setPresented] = useState(undefined);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(() => {
        let newPresented = false;
        if (window?.localStorage) {
            if (localStorage.getItem("_cookie_consent")) {
                setSelectedOptions(JSON.parse(localStorage.getItem("_cookie_consent")));
                newPresented = true;
            } else {
                setSelectedOptions(COOKIES);
            }
        }
        setPresented(newPresented);
    }, []);

    useEffect(() => {
        if (presented === undefined) {
            return;
        }
        if (presented === false) {
            setModalIsOpen(true);
        }
    }, [presented]);

    useEffect(() => {
        if (displayCookieConsent) {
            if (selectedOptions.length === 0) {
                setSelectedOptions(COOKIES);
            }
            setModalIsOpen(displayCookieConsent);
        }
    }, [displayCookieConsent]);

    useEffect(() => {
        let necessaryAllowed = false;
        for (const option of selectedOptions) {
            if (option.name === 'necessary') {
                necessaryAllowed = option.status;
                break;
            }
        }

        if (selectedOptions.length > 0) {
            localStorage.setItem("_cookie_consent", JSON.stringify(selectedOptions));
            setDisplayCookieConsent(false);
        }
    }, [selectedOptions]);

    const handleCookieToggled = (event) => {
        const {name, checked: value} = event.target;

        let newValues = [];

        for (const oldValue of selectedOptions) {
            if (oldValue.options) {
                let newOptions = [];
                for (const oldOption of oldValue.options) {
                    if (oldOption.name.toLowerCase() === name.toLowerCase()) {
                        newOptions = [...newOptions, {name: oldOption.name, status: value}];
                    } else {
                        newOptions = [...newOptions, oldOption];
                    }
                }

                oldValue.options = newOptions;
            }

            if (oldValue.name.toLowerCase() === name.toLowerCase()) {
                newValues = [...newValues, {name: cookie.name, status: value}];
            } else {
                newValues = [...newValues, oldValue];
            }
        }

        setSelectedOptions(newValues);
    }

    const handleDecline = () => {
        toggleAll(false);

        setPresented(true);
        setModalIsOpen(false);
        setDisplayCookieConsent(false);
    }

    const handleAccept = () => {
        toggleAll(true);

        setPresented(true);
        setModalIsOpen(false);
        setDisplayCookieConsent(false);
    }

    const toggleAll = (newStatus) => {
        let newValues = [];

        for (const cookie of selectedOptions) {

            if (cookie.status !== undefined) {
                newValues = [...newValues, {name: cookie.name, status: newStatus}];
            }
            if (cookie.options !== undefined) {
                let newOptions = [];
                for (const option of cookie.options) {
                    newOptions = [...newOptions, {name: option.name, status: newStatus}];
                }
                const alreadyExistsIndex = newValues.findIndex(c => c.name === cookie.name);
                if (alreadyExistsIndex > -1) {
                    newValues.splice(alreadyExistsIndex, 1);
                }
                newValues = [...newValues, {name: cookie.name, options: newOptions}];
            }
        }

        setSelectedOptions(newValues);
    }

    const handleSave = () => {
        setPresented(true);
        setModalIsOpen(false);
        setDisplayCookieConsent(false);
    }

    return (
        <CookieConsentContext.Provider value={{
            status: selectedOptions,
            setStatus: setSelectedOptions
        }}>
            {children}

            <CookieConsentDialog modalIsOpen={modalIsOpen} selectedOptions={selectedOptions} onCookieToggled={handleCookieToggled} onSave={handleSave} onDecline={handleDecline} onAccept={handleAccept} />
        </CookieConsentContext.Provider>
    )
}

const useCookieConsent = (cookie) => {
    const {status, setStatus} = useContext(CookieConsentContext);

    let result = undefined;
    for (const option of status) {
        if (option.name.toLowerCase() === cookie.toLowerCase()) {
            result = option.status;
            break;
        }
        if (result === undefined && option.options) {
            for (const co of option.options) {
                if (co.name.toLowerCase() === cookie.toLowerCase()) {
                    result = co.status;
                    break;
                }
            }
        }
    }

    if (!result) {
        result = false;
    }

    const update = (name, newStatus) => {
        let newValues = [];
        for (const cookie of status) {
            if (cookie.name.toLowerCase() === name.toLowerCase()) {
                newValues = [...newValues, {name: cookie.name, status: newStatus}];
            } else {
                newValues = [...newValues, {name: cookie.name, status: cookie.status}];
            }

            if (cookie.options !== undefined) {
                let newOptions = [];
                for (const option of cookie.options) {
                    if (option.name.toLowerCase() === name.toLowerCase()) {
                        newOptions = [...newOptions, {name: option.name, status: newStatus}];
                    } else {
                        newOptions = [...newOptions, {name: option.name, status: option.status}];
                    }

                }
                const alreadyExistsIndex = newValues.findIndex(c => c.name === cookie.name);
                if (alreadyExistsIndex > -1) {
                    newValues.splice(alreadyExistsIndex, 1);
                }
                newValues = [...newValues, {name: cookie.name, options: newOptions}];
            }
        }

        setStatus(newValues);
    }

    return {
        cookieConsent: result,
        update
    }
}
export {useCookieConsent};
