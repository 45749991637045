import theme from "@/styles/theme";
import { Container, Typography } from "@mui/material";
import Box from "@mui/material/Box";

export default function PageDisabled() {
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{ backgroundColor: theme.palette.common.white, padding: "4rem" }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          color="common.black"
        >
          Geplante Wartungsarbeiten
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Liebe Nutzer:innen von showcase.nrw,
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          aufgrund dringend notwendiger Arbeiten an unserem Server ist unsere
          Website leider kurzzeitig nicht erreichbar. Die geplante Downtime wird
          voraussichtlich ca. 2 Stunden dauern. Wir entschuldigen uns für die
          Unannehmlichkeiten und danken für Eure Geduld!
        </Typography>
        <Typography variant="body1" align="center">
          Euer showcase.nrw Team
        </Typography>
      </Container>
    </Box>
  );
}
