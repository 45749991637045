import React from "react";

export const ExternalLinkIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M8.99999928,16.5 C9.38437414,16.5 9.76781201,16.3535156 10.0603116,16.0605469 L19.4999993,6.62343693 L21.439687,8.56312466 C21.8688748,8.99161005 22.5121872,9.1181252 23.0746872,8.88834429 C23.6343749,8.65312529 23.9999993,8.10468721 23.9999993,7.5 L23.9999993,1.125 C23.9999993,0.503437042 23.4984362,0 22.8749993,0 L16.4999993,0 C15.8934367,0 15.345937,0.365484238 15.1143744,0.92578125 C14.8814528,1.48687506 15.010359,2.13140631 15.4395926,2.56078148 L17.3812487,4.5 L7.940624,13.9406247 C7.3546865,14.5265622 7.3546865,15.475781 7.940624,16.0617185 C8.23124957,16.3546872 8.61562443,16.5 8.99999928,16.5 Z M16.4999993,16.5 L16.4999993,21 L2.99999928,21 L2.99999928,7.5 L7.49999928,7.5 C8.32828116,7.5 8.99999928,6.82874966 8.99999928,6 C8.99999928,5.17125034 8.32828116,4.5 7.49999928,4.5 L2.99999928,4.5 C1.34296846,4.5 -7.15255737e-07,5.84296846 -7.15255737e-07,7.5 L-7.15255737e-07,21 C-7.15255737e-07,22.6565623 1.34296775,24 2.99999928,24 L16.4999993,24 C18.1570308,24 19.4999993,22.6565623 19.4999993,21 L19.4999993,16.5 C19.4999993,15.7191171 18.9062282,15.0794117 18.1446564,15.0068543 L17.9999993,15 C17.1717181,15 16.4999993,15.6717181 16.4999993,16.5 Z"></path>
      </svg>
    </>
  );
};
