import React from "react";

export const UsersIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path
          d="M19.2,8 C20.8567497,8 22.2,6.65674973 22.2,5 C22.2,3.34325027 20.8575005,2 19.2,2 C17.5432503,2 16.2,3.34325027 16.2,5 C16.2,6.65674973 17.5424995,8 19.2,8 Z M4.79999943,8 C6.45674973,8 7.79999943,6.65674973 7.79999943,5 C7.79999943,3.34325027 6.45749989,2 4.79999943,2 C3.1432497,2 1.79999943,3.34325027 1.79999943,5 C1.79999943,6.65674973 3.1432497,8 4.79999943,8 Z M11.9962498,14 C14.1491249,14 15.8625,12.2540001 15.8625,10.1 C15.8625,7.94599991 14.1172497,6.2 11.9962498,6.2 C9.84337463,6.2 8.12999954,7.94599991 8.12999954,10.1 C8.09624977,12.2524998 9.84375,14 11.9962498,14 Z M11.9962498,12.2 C10.8412491,12.2 9.89624977,11.2587498 9.89624977,10.1 C9.89624977,8.94125023 10.8375,8 11.9962498,8 C13.153125,8 14.0947495,8.94199982 14.0947495,10.1 C14.0947495,11.2580002 13.1512505,12.2 11.9962498,12.2 Z M23.3377487,13.4 C23.7037491,13.4 24,13.0850002 24,12.6987495 C24,10.7675001 22.5224991,9.2 20.6962509,9.2 L18.3772511,9.2 C17.8972515,9.2 17.4442509,9.3138876 17.0325005,9.50900021 C17.0549995,9.70625 17.0924995,9.89750023 17.0924995,10.1 C17.0924995,11.3641247 16.6132496,12.5078747 15.8489994,13.4 L23.3377487,13.4 Z M8.14199982,13.4 C7.37624989,12.5075005 6.89624977,11.3637499 6.89624977,10.1 C6.89624977,9.89566231 6.93309002,9.70137482 6.95658703,9.50337486 C6.54750023,9.27875023 6.09750023,9.2 5.62124977,9.2 L3.30300007,9.2 C1.47900009,9.2 0,10.7675001 0,12.6987495 C0,13.0850002 0.295762253,13.4 0.660749817,13.4 L8.14199982,13.4 Z M18.1350002,21.2 C18.7237495,21.2 19.2,20.7537502 19.2,20.1987495 C19.2,17.4387505 16.8149998,15.2 13.8712498,15.2 L10.1287502,15.2 C7.18500023,15.2 4.8,17.4387505 4.8,20.1987495 C4.8,20.7537502 5.27624989,21.2 5.86499977,21.2 L18.1350002,21.2 Z M17.2889992,19.4 L6.67875023,19.4 C7.07137527,18.0214996 8.45475025,17 10.0964996,17 L13.8712498,17 C15.5126244,17 16.8959999,18.0214996 17.2889992,19.4 Z"
        ></path>
      </svg>
    </>
  );
};
