import React from "react";

export const LogoutIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M7.875,23 C8.49843764,23 9,22.4984369 9,21.875 C9,21.2515631 8.49843764,20.75 7.875,20.75 L4.5,20.75 C3.26249957,20.75 2.24999928,19.7374997 2.24999928,18.5 L2.24999928,6.5 C2.24999928,5.26250029 3.26249957,4.25 4.5,4.25 L7.875,4.25 C8.49843764,4.25 9,3.74609375 9,3.125 C9,2.50343704 8.49843764,2 7.875,2 L4.5,2 C2.0146873,2 0,4.01468801 0,6.5 L0,18.5 C0,20.985312 2.0146873,23 4.5,23 L7.875,23 Z M15.4335938,20.000422 C15.8115005,20.000422 16.1849999,19.864203 16.4765625,19.6048913 L23.6203122,13.3658285 C23.8640628,13.1515622 24,12.8468747 24,12.5234375 C24,12.2000003 23.8640628,11.8953128 23.6203122,11.6843753 L16.4765625,5.39374971 C16.0151253,4.98359346 15.3515625,4.88375092 14.7848439,5.13739061 C14.2312503,5.38906193 13.875,5.93281221 13.875,6.53281307 L13.875,9.12546921 L8.25,9.12546921 C7.21593761,9.12546921 6.375,9.96640611 6.375,11.0004685 L6.375,14.0004692 C6.375,15.0345316 7.21593761,15.8754692 8.25,15.8754692 L13.875,15.8754692 L13.875,18.4681253 C13.875,19.0657816 14.2324219,19.6123443 14.7848439,19.8612504 C14.9928746,19.9550004 15.2140317,20.000422 15.4335938,20.000422 Z M16.125,16.90625 L16.125,13.5828128 L8.625,13.5828128 L8.625,11.3328128 L16.125,11.3328128 L16.125,8.05156279 L21.1781244,12.4831257 L16.125,16.90625 Z"></path>
      </svg>
    </>
  );
};
