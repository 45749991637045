import React from "react";

export const IconSquare = ({ ...props }) => {
  return (
    <>
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
      >
        <title>icons-square</title>
        <g
          id="icons-square"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <rect
            id="Rectangle"
            fill="#E2E2E2"
            x="0"
            y="0"
            width="20"
            height="20"
          ></rect>
        </g>
      </svg>
    </>
  );
};