import React from "react";

export const CreatemusicIcon = ({...props}) => {
  return (
    <>
      <svg
        viewBox="0 0 24 24"
      >
        <g>
          <path
            d="M24,19.7565411 L23.841369,15.9493967 C23.841369,15.4735037 23.6034225,15.0769261 23.2068449,14.9182951 C22.8102674,14.6803486 22.3343743,14.6803486 21.9377968,14.9182951 L9.24731567,21.3428512 C8.69210712,21.6601132 8.37484509,22.2946373 8.61279161,22.9291613 C8.69210712,23.2464234 8.93005364,23.4843699 9.16800016,23.6430009 C9.40594668,23.8016319 9.72320871,23.8809474 10.0404707,23.8016319 L22.8895829,21.2635357 C23.6034225,20.9462737 24,20.3910651 24,19.7565411"
          ></path>
          <path
            d="M2.50549756,14.6803486 L11.0715723,4.05207063 C11.3095189,3.7348086 11.4681499,3.33823107 11.3888344,2.94165353 C11.3095189,2.54507599 11.0715723,2.14849846 10.6749948,1.98986744 L7.50237451,0.165610779 C7.18511248,0.00697976463 6.70921944,-0.0723357425 6.39195741,0.0862952718 C5.99537987,0.244926286 5.67811784,0.482872808 5.51948683,0.879450344 L0.126032341,13.331985 C-0.191229688,13.966509 0.126032341,14.7596641 0.681240891,15.0769261 C0.681240891,15.0769261 0.760556398,15.0769261 0.760556398,15.1562416 C1.31576495,15.3148726 2.02960451,15.2355571 2.50549756,14.6803486"
          ></path>
          <path
            d="M17.5754439,4.13138614 L5.83674886,16.1080277 C5.36085581,16.5839208 5.28154031,17.3770758 5.75743335,17.9322844 C5.83674886,18.0909154 5.99537987,18.1702309 6.15401089,18.2495464 C6.55058842,18.4874929 7.10579697,18.4874929 7.58169002,18.1702309 L22.4930054,8.65237005 C22.8895829,8.41442352 23.1275294,7.85921497 23.1275294,7.38332193 C23.1275294,6.90742889 22.7309519,6.51085135 22.3343743,6.19358932 C21.9377968,5.8763273 19.5583316,3.97275512 19.1617541,3.7348086 C18.844492,3.57617759 17.9720215,3.7348086 17.5754439,4.13138614"
          ></path>
        </g>
      </svg>
    </>
  );
};