import React from "react";

export const UseraddIcon = ({...props}) => {
  return (
    <>
      <path
        d="M8.4,11.6 C11.0512505,11.6 13.2,9.45087509 13.2,6.8 C13.2,4.14912491 11.0512505,2 8.4,2 C5.74875011,2 3.6,4.14912491 3.6,6.8 C3.6,9.45087509 5.74875011,11.6 8.4,11.6 Z M20.4,14 C20.8950005,14 21.3,13.596875 21.3,13.1 L21.3,11.3 L23.1,11.3 C23.5987495,11.3 24,10.8987501 24,10.4 C24,9.90124989 23.5987495,9.5 23.1,9.5 L21.3,9.5 L21.3,7.7 C21.3,7.20499954 20.8987495,6.8 20.4,6.8 C19.9012505,6.8 19.5,7.203125 19.5,7.7 L19.5,9.5 L17.7,9.5 C17.2049995,9.5 16.8,9.90500011 16.8,10.4 C16.8,10.8949999 17.203125,11.3 17.7,11.3 L19.5,11.3 L19.5,13.1 C19.5,13.5987495 19.9049995,14 20.4,14 Z M15.5010006,21.2 C16.21875,21.2 16.8,20.61875 16.8,19.8987495 C16.8,16.3100002 13.8899998,13.4 10.3012505,13.4 L6.49875011,13.4 C2.91037502,13.4 0,16.3100002 0,19.8987495 C0,20.61875 0.58200016,21.2 1.29975014,21.2 L15.5010006,21.2 Z"
      ></path>
    </>
  );
};