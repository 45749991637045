import { Burger } from "@/components/Burger";
import { ShowcaseLogo } from "@/components/icons";
import { NavigationDashboardMenuContainer } from "@/components/navigation/NavigationDashboardMenuContainer";
import { NavigationMobileMenuFirstLevelItem } from "@/components/navigation/NavigationMobileMenuFirstLevelItem";
import { NavigationMobileMenuItem } from "@/components/navigation/NavigationMobileMenuItem";
import AppContext from "@/context/AppContext";
import { futuraNextCondensedExtraBold } from "@/styles/fonts";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import { grey } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { useContext } from "react";
import { DashboardMenu } from "./DashboardMenu";
import { NavigationLogin } from "./NavigationLogin";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    left: "0!important",
    minWidth: "100vw",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    boxShadow: "rgba(0, 0, 0, 0.3) 0px 10px 12px",
    "& .MuiList-root": {
      padding: "0",
      "& .MuiMenuItem-root.MenuItem--Wrapper": {
        minHeight: "unset",
        marginLeft: "-1rem",
        padding: "0 1rem",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&:active": {
          backgroundColor: "transparent",
        },
        "&:not(:last-of-type)": {
          borderBottom: "1px solid #cd8429",
        },
        "&:first-of-type": {
          borderTop: "1px solid #cd8429",
        },
      },
    },
  },
}));

const StyledAccordion = styled((props) => <Accordion {...props} />)(
  ({ theme }) => ({
    "&.MuiAccordion-root": {
      padding: "0",
      boxShadow: "none",
      "& .MuiAccordionSummary-root": {
        minHeight: "unset",
        "&:hover": {
          "& .MuiMenuItem-root.MenuItem--FirstLevel": {
            backgroundColor: "transparent",
          },
        },
        "& .MuiMenuItem-root.MenuItem--FirstLevel": {
          marginLeft: "-1rem",
          fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
          fontSize: "1.5rem",
        },
        "& .MuiMenuItem-root": {
          textTransform: "uppercase",
        },

        "&.first-level-is-active": {
          "& .MuiMenuItem-root.MenuItem--FirstLevel": {
            color: theme.palette.secondary.main,
          },
          "& .MuiSvgIcon-root": {
            color: theme.palette.secondary.main,
          },
        },
      },
      "& .MuiAccordionSummary-content": {
        margin: "0",
      },
    },
  }),
);

const StyledAccordionDetails = styled((props) => (
  <AccordionDetails {...props} />
))(({ theme }) => ({
  padding: ".25rem 1.5rem",
  backgroundColor: theme.palette.secondary.main,
  "& .MuiMenuItem-root.MenuItem--SecondLevel": {
    padding: ".25rem 1rem 0.25rem 1rem",
    minHeight: "unset",
    fontSize: "1.5rem",
    fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
    "&__is-active": {
      color: theme.palette.primary.main,
    },
  },
  "& .MuiMenuItem-root.MenuItem--ThirdLevel": {
    minHeight: "unset",
    padding: ".125rem 1rem .125rem 2rem",
    fontSize: "1.25rem",
    fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:active": {
      backgroundColor: "transparent",
    },
    "&__is-active": {
      color: theme.palette.primary.main,
    },
  },
}));

const StyledAccordionDetailsLoginMenu = styled((props) => (
  <AccordionDetails {...props} />
))(({ theme }) => ({
  padding: "0",
  "& .navigation--loginmenu__container": {
    backgroundColor: grey[900],
  },
}));

export const NavigationMobile = () => {
  const { isAuthenticated } = useContext(AppContext);
  const [expanded, setExpanded] = React.useState(false);
  const router = useRouter();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setExpanded(null);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: { xs: "block", lg: "none" } }}>
      <AppBar position="fixed" color="primary" elevation={0}>
        <Toolbar
          disableGutters={true}
          sx={{
            "&.MuiToolbar-root": {
              height: "3.125rem",
              px: 0,
              py: 0,
              minHeight: "unset",
            },
          }}
        >
          <Container maxWidth="xl">
            <Grid container justifyContent="center">
              <Grid item xs={12} sm={10}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item xs="auto">
                    <Link href="/" passHref legacyBehavior>
                      <Button
                        onClick={handleClose}
                        disableRipple
                        sx={{
                          top: "0",
                          marginBottom: "-.75rem",
                          padding: ".5rem 0",
                          px: 0,
                          zIndex: "1500",
                          "&.MuiButton-text": {
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          },
                          "& > span": {
                            width: "92px!important",
                            height: "43px!important",
                          },
                        }}
                      >
                        <ShowcaseLogo />
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs="auto">
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs="auto">
                        <IconButton
                          color="inherit"
                          aria-label="menu"
                          id="customized-button"
                          aria-controls="customized-menu"
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant="contained"
                          disableRipple
                          onClick={handleClick}
                          sx={{
                            "&.MuiIconButton-root": {
                              padding: "0",
                              height: "3.125rem",
                              "&:hover": {
                                backgroundColor: "transparent",
                              },
                            },
                          }}
                        >
                          <Burger open={open} />
                        </IconButton>
                        <StyledMenu
                          id="customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          TransitionComponent={Slide}
                        >
                          <MenuItem
                            component="div"
                            className="MenuItem--Wrapper"
                          >
                            <StyledAccordion
                              expanded={expanded === "panel1"}
                              onChange={handleChange("panel1")}
                            >
                              <NavigationMobileMenuFirstLevelItem title="Musik" />
                              <StyledAccordionDetails>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--SecondLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/musik/suchen"
                                    label="Musik suchen"
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--SecondLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/musik/entdecken"
                                    label="Musik entdecken"
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--SecondLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/musik"
                                    label="Musik Index"
                                  />
                                </MenuItem>
                              </StyledAccordionDetails>
                            </StyledAccordion>
                          </MenuItem>
                          <MenuItem
                            component="div"
                            className="MenuItem--Wrapper"
                          >
                            <StyledAccordion
                              expanded={expanded === "panel2"}
                              onChange={handleChange("panel2")}
                            >
                              <NavigationMobileMenuFirstLevelItem title="Netzwerk" />
                              <StyledAccordionDetails>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--SecondLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/netzwerk/showcase-map"
                                    label="Showcase Map"
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--SecondLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/netzwerk/ueber-uns"
                                    label="Über uns"
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--ThirdLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/netzwerk/ueber-uns/createmusic"
                                    label="Create Music"
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--ThirdLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/netzwerk/ueber-uns/newheimatsounds"
                                    label="New. Heimat. Sounds."
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--SecondLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/netzwerk/partner"
                                    label="Partner"
                                  />
                                </MenuItem>
                              </StyledAccordionDetails>
                            </StyledAccordion>
                          </MenuItem>
                          <MenuItem
                            component="div"
                            className="MenuItem--Wrapper"
                          >
                            <StyledAccordion
                              expanded={expanded === "panel3"}
                              onChange={handleChange("panel3")}
                            >
                              <NavigationMobileMenuFirstLevelItem title="Förderung" />
                              <StyledAccordionDetails>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--SecondLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/foerderung/foerderfinder"
                                    label="Förderfinder"
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--SecondLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/foerderung/workshops"
                                    label="Workshops"
                                  />
                                </MenuItem>
                                <MenuItem
                                  onClick={handleClose}
                                  disableRipple
                                  className="MenuItem--SecondLevel"
                                >
                                  <NavigationMobileMenuItem
                                    onClick={handleClose}
                                    href="/foerderung/wissenswertes"
                                    label="Wissenswertes"
                                  />
                                </MenuItem>
                              </StyledAccordionDetails>
                            </StyledAccordion>
                          </MenuItem>
                          {!isAuthenticated && (
                            <MenuItem
                              component="div"
                              className="MenuItem--Wrapper"
                            >
                              <StyledAccordion
                                expanded={expanded === "panel4"}
                                onChange={handleChange("panel4")}
                              >
                                <NavigationMobileMenuFirstLevelItem title="Login" />
                                <StyledAccordionDetailsLoginMenu>
                                  <NavigationLogin
                                    onLogin={() => {
                                      setExpanded(null);
                                      handleClose();
                                    }}
                                    setAnchorEl={setAnchorEl}
                                  />
                                </StyledAccordionDetailsLoginMenu>
                              </StyledAccordion>
                            </MenuItem>
                          )}
                        </StyledMenu>
                      </Grid>
                      {isAuthenticated && (
                        <Grid item xs="auto">
                          <NavigationDashboardMenuContainer isMobile={true}>
                            <DashboardMenu />
                          </NavigationDashboardMenuContainer>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar
        sx={{
          "&.MuiToolbar-root": {
            height: "3.125rem",
            px: 0,
            py: 0,
            minHeight: "unset",
          },
        }}
      />
    </Box>
  );
};
