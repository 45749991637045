import React from "react";

export const VenueIcon = ({...props}) => {
  return (
    <>
      <svg viewBox="0 0 24 24">
        <g>
          <path d="M10.2499967,24 C13.1515571,24 15.5,22.3171888 15.5,20.2500023 L15.5,20.2500023 L15.5,6.94219839 L18.9218663,5.93954208 C19.5605378,5.75204219 20,5.16610506 20,4.49813672 L20,4.49813672 L20,1.49813859 C20,1.02376411 19.7749914,0.577514903 19.3953038,0.296452475 C19.0203041,0.015169748 18.5281169,-0.0727823344 18.0781169,0.0620304137 L18.0781169,0.0620304137 L13.5490574,1.3982961 C12.9277294,1.59142075 12.5,2.17173312 12.5,2.82798271 L12.5,2.82798271 L12.5,16.832348 C11.7734333,16.5862544 11.017809,16.4573483 10.2499967,16.4573483 C7.34984252,16.4573483 5,18.0984415 5,20.2078151 C5,22.3171888 7.34843633,24 10.2499967,24 Z"></path>
        </g>
      </svg>
    </>
  );
};