import { useEffect } from "react";
import Router from "next/router";
import Cookie from "js-cookie";
import axios from "axios";
import { createProfile, getMe, getProfileByEMail, updateProfileUser } from "./api";

const sha1 = require("js-sha1");
const API_URL = process.env.NEXT_PUBLIC_API_URL || "http://localhost:1337";

export const registerUser = (username, email, password) => {
  //prevent function from being ran on the server
  if (typeof window === "undefined") {
    return;
  }
  return new Promise((resolve, reject) => {
    const hashed = sha1(password);
    const prefix = hashed.substring(0, 5);
    const suffix = hashed.substring(5).toUpperCase();

    axios
      .get(`https://api.pwnedpasswords.com/range/${prefix}`)
      .then((res) => {
        const hashes = res.data.split(/\r?\n/);
        for (const keyValue of hashes) {
          const hash = keyValue.split(/\:/)[0];
          const count = keyValue.split(/\:/)[1];
          if (suffix === hash) {
            console.debug("leaked");
            throw new Error("Passwort geleaked - wähle bitte ein anderes sicheres Passwort. Siehe https://haveibeenpwned.com/Passwords")
          }
        }
      })
      .then(() => {
        axios.post(`${API_URL}/auth/local/register`, {
          username,
          email,
          password,
        })
        .then((res) => {
          //set token response from Strapi for server validation
          Cookie.set("token", res.data.jwt);
    
          //resolve the promise to set loading to false in SignUp form
          resolve(res);
          //redirect back to home page for restaurance selection
          Router.push(`/register/confirm?email=${email}`);
        })
        .catch((error) => {
          //reject the promise and pass the error object back to the form
          reject(error);
        });
      })
      .catch((error) => {
        reject(error);
      });
      

    /*
    if (true) return;
    axios
      .post(`${API_URL}/auth/local/register`, { username, email, password })
      .then((res) => {
        //set token response from Strapi for server validation
        Cookie.set("token", res.data.jwt);

        //resolve the promise to set loading to false in SignUp form
        resolve(res);
        //redirect back to home page for restaurance selection
        Router.push("/");
      })
      .catch((error) => {
        //reject the promise and pass the error object back to the form
        reject(error);
      });*/
  });
};

export const login = (identifier, password, remember = 'off') => {
  //prevent function from being ran on the server
  if (typeof window === "undefined") {
    return;
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/auth/local/`, { identifier, password })
      .then(async(res) => {
        //set token response from Strapi for server validation

        if (remember === 'on') {
          Cookie.set("token", res.data.jwt, { expires: 30});
        } else {
          Cookie.set("token", res.data.jwt);
        }

        if (!res.data.user.profile) {
          const profileCheck = await getProfileByEMail(identifier);
          if (!profileCheck || profileCheck.length === 0) {
            await createProfile(res.data.user);
          } else {
            await updateProfileUser(profileCheck[0].id, {user: res.data.user.id});
          }
        }

        //resolve the promise to set loading to false in SignUp form
        resolve(res);
        //redirect back to home page for restaurance selection
        Router.push("/secure");
      })
      .catch(({ response }) => {
        //reject the promise and pass the error object back to the form
        reject(response.data);
      });
  });
};

export const logout = () => {
  //remove token and user cookie
  Cookie.remove("token");
  Cookie.remove("profile");
  delete window.__user;
  delete window.__profile;
  delete window.__band;
  // sync logout between multiple windows
  window.localStorage.setItem("logout", Date.now());
  //redirect to the home page
  Router.push("/");
};

export const withAuthSync = (Component) => {
  const Wrapper = (props) => {
    const syncLogout = (event) => {
      if (event.key === "logout") {
        Router.push("/login");
      }
    };

    useEffect(() => {
      window.addEventListener("storage", syncLogout);

      return () => {
        window.removeEventListener("storage", syncLogout);
        window.localStorage.removeItem("logout");
      };
    }, []);

    return <Component {...props} />;
  };

  if (Component.getInitialProps) {
    Wrapper.getInitialProps = Component.getInitialProps;
  }

  return Wrapper;
};
