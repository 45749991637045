import { Page } from "@/components/Page";
import PageDisabled from "@/components/PageDisabled";
import { ConfirmProvider } from "@/components/material-ui-confirm";
import AppContext from "@/context/AppContext";
import CookieConsentProvider from "@/context/CookieConsentProvider";
import { getMe, getProfile } from "@/lib/api";
import {
  basierCirleBold,
  basierCirleRegular,
  futuraNextCondensedExtraBold,
} from "@/styles/fonts";
import theme from "@/styles/theme";
import { Alert, Snackbar } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import Cookie from "js-cookie";
import { DefaultSeo } from "next-seo";
import App from "next/app";
import Head from "next/head";
import { IntlProvider } from "react-intl";
import { RecoilRoot } from "recoil";
import { SWRConfig } from "swr";
import "../styles/_base.scss";

/*
export function reportWebVitals(metric) {
  if (metric.label === 'web-vital') {
    console.log(metric) // The metric object ({ id, name, startTime, value, label }) is logged to the console
  }

  switch (metric.name) {
    case 'FCP':
      // handle FCP results
      break
    case 'LCP':
      // handle LCP results
      break
    case 'CLS':
      // handle CLS results
      break
    case 'FID':
      // handle FID results
      break
    case 'TTFB':
      // handle TTFB results
      break
    case 'INP':
      // handle INP results (note: INP is still an experimental metric)
      break
    default:
      break
  }
}
*/

class ShowcaseApp extends App {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      loggedInUser: null,
      profile: null,
      band: null,
      token: null,
      snackbar: null,
      cookiesDisabled: true,
      displayCookieConsent: false,
    };
  }

  componentDidMount() {
    const token = Cookie.get("token");
    const bandCookie = localStorage.getItem("band"); // Cookie.get("band"); //Buffer.from(Cookie.get("band"), 'base64').toString('ascii');
    const profileCookie = localStorage.getItem("profile"); //Cookie.get("profile");
    const profile = profileCookie ? JSON.parse(profileCookie) : null;
    const band = bandCookie ? JSON.parse(bandCookie) : null;
    if (token) {
      // authenticate the token on the server and place set user object
      fetch(`${process.env.NEXT_PUBLIC_API_URL}/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async (res) => {
        // if res comes back not valid, token is not valid
        // delete the token and log the user out on client
        if (!res.ok) {
          Cookie.remove("token");
          this.setState({ user: null, token: null, band: null, profile: null });
          this.setProfile(null);
          this.setBand(null);
          return null;
        } else {
          const user = await res.json();
          this.setToken(token);
          this.setUser(user, token);
          this.setProfile(profile);
          this.setBand(band);
        }
      });
    }
  }

  setUser = async (user, token = null) => {
    if (user == null) {
      this.setState({ user: null, profile: null });
      return;
    }

    let profile = user?.profile;
    if (!profile || Number(user.profile) === user.profile) {
      const me = await getMe();
      profile = await getProfile(me.profile);
    }

    this.setState({ user: user, profile: profile });
  };

  setLoggedInUser = (user) => {
    this.setState({ loggedInUser: user });
  };

  setBand = (band) => {
    this.setState({ band: band });
    if (band) {
      //Cookie.set("band", Buffer.from(JSON.stringify(band)).toString('base64'));
      localStorage.setItem("band", JSON.stringify(band));
      //Cookie.set("band", JSON.stringify(band));
    } else {
      //Cookie.remove("band");
      localStorage.removeItem("band");
    }
  };

  setProfile = (profile) => {
    this.setState({ profile: profile });
    if (profile) {
      //Cookie.set("profile", Buffer.from(JSON.stringify(profile)).toString('base64'));
      //Cookie.set("profile", JSON.stringify(profile));
      localStorage.setItem("profile", JSON.stringify(profile));
    } else {
      //Cookie.remove("profile");
      localStorage.removeItem("profile");
    }
  };

  setToken = (token) => {
    this.setState({ token: token });
  };

  setSnackbar = (content) => {
    this.setState({ snackbar: content });
  };

  handleSnackbarClosed = () => {
    this.setState({ snackbar: null });
  };

  setDisplayCookieConsent = (newState) => {
    this.setState({ displayCookieConsent: newState });
  };

  render() {
    const { Component, pageProps } = this.props;

    return (
      <AppContext.Provider
        value={{
          user: this.state.user,
          setUser: this.setUser,
          profile: this.state.profile,
          band: this.state.band,
          isAuthenticated: !!this.state.user,
          loggedInUser: this.state.loggedInUser,
          setLoggedInUser: this.setLoggedInUser,
          setProfile: this.setProfile,
          setBand: this.setBand,
          setSnackbar: this.setSnackbar,
          displayCookieConsent: this.state.displayCookieConsent,
          setDisplayCookieConsent: this.setDisplayCookieConsent,
        }}
      >
        <IntlProvider locale="de" defaultLocale="de">
          <Head>
            <meta name="env" content={process.env.VERCEL_ENV} />
            <style jsx global>{`
              html {
                font-family: ${basierCirleRegular.style.fontFamily};
              }

              :root {
                --font-basier-cirlce-regular: ${basierCirleRegular.style
                  .fontFamily};
                --font-basier-cirlce-bold: ${basierCirleBold.style.fontFamily};
                --font-futura-extra-bold: ${futuraNextCondensedExtraBold.style
                  .fontFamily};
              }
            `}</style>
          </Head>

          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <CssBaseline />

              <CookieConsentProvider>
                <SWRConfig
                  value={{
                    provider: () => new Map(),
                    fetcher: async (url) => {
                      let headers = {};
                      const bearer = Cookie.get("token");
                      if (bearer) {
                        headers = {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${bearer}`,
                        };
                      }
                      const res = await fetch(url, {
                        headers,
                      });
                      return res.json();
                    },
                  }}
                >
                  <RecoilRoot>
                    {process.env.NEXT_PUBLIC_PAGE_DISABLED === "true" ||
                    (typeof process.env.NEXT_PUBLIC_PAGE_DISABLED ===
                      "boolean" &&
                      process.env.NEXT_PUBLIC_PAGE_DISABLED === true) ? (
                      <PageDisabled />
                    ) : (
                      <>
                        <ConfirmProvider>
                          <DefaultSeo
                            title="Support your local scene"
                            titleTemplate="%s | showcase.nrw"
                            description="Showcase.nrw ist Pop, Netzwerk, Förderung. Eine Plattform für Musiker:innen und Bands aus NRW. Filterbar bis auf die lokale Szene und auf’s Genre genau."
                            openGraph={{
                              type: "website",
                              description:
                                "Showcase.nrw ist Pop, Netzwerk, Förderung. Eine Plattform für Musiker:innen und Bands aus NRW. Filterbar bis auf die lokale Szene und auf’s Genre genau.",
                              locale: "de_DE",
                              url: "https://www.showcase.nrw",
                              siteName:
                                "showcase.nrw - Support your locale scene",
                              images: [
                                {
                                  url: "https://res.cloudinary.com/dz3kn0vn4/image/upload/v1668615878/Showcase_Share_Img_a20e84a316.jpg",
                                  width: 1024,
                                  height: 512,
                                  alt: "showcase.nrw social share image",
                                  type: "image/jpg",
                                },
                              ],
                            }}
                          />
                          <Page {...this.props} />
                        </ConfirmProvider>

                        <Snackbar
                          open={this.state.snackbar !== null}
                          autoHideDuration={6000}
                          onClose={this.handleSnackbarClosed}
                        >
                          <Alert
                            onClose={this.handleSnackbarClosed}
                            severity={this.state.snackbar?.type || "success"}
                            sx={{ width: "100%" }}
                          >
                            {this.state.snackbar?.content ||
                              this.state.snackbar}
                          </Alert>
                        </Snackbar>
                      </>
                    )}
                  </RecoilRoot>
                </SWRConfig>
              </CookieConsentProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </IntlProvider>
      </AppContext.Provider>
    );
  }
}

export default ShowcaseApp;
