import { useRouter } from "next/router";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import theme from "@/styles/theme";

export const NavigationMobileMenuFirstLevelItem = ({ title }) => {
  const router = useRouter();

  return (
    <MuiAccordionSummary
      sx={style}
      expandIcon={
        <KeyboardArrowRightIcon
          sx={{ fontSize: 32, color: theme.palette.common.white }}
        />
      }
      aria-controls="panel1a-content"
      id="panel1a-header"
      className={
        router.pathname
          .toLowerCase()
          .replace("oe", "ö")
          .indexOf(`/${title.toLowerCase()}`) === 0
          ? "first-level-is-active"
          : ""
      }
    >
      <MenuItem disableRipple className="MenuItem--FirstLevel">
        {title}
      </MenuItem>
    </MuiAccordionSummary>
  );
};

const style = {
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
};
