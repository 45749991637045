import React from "react";
// create auth context with default value

// set backup default for isAuthenticated if none is provided in Provider
const CookieConsentContext = React.createContext({
    status: [
        {
            name: 'Technisch notwendig',
            status: false,
        },
        {
            name: 'Funktionell',
            options: [
                {
                    name: 'Mapbox',
                    status: false
                },
                {
                    name: 'Soundcloud',
                    status: false
                }
            ]
        },
        {
            name: 'Marketing',
            options: [
                {
                    name: 'Plausible',
                    status: false
                },
            ]
        }
    ]
});
export default CookieConsentContext;
