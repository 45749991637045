import React from "react";

export const MessageIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M7.4997072,23.3090899 C7.4997072,23.7660905 8.02679577,24.0332796 8.39592199,23.7637603 L8.39592199,23.7637603 L14.2503806,19.372682 L21.0001171,19.372682 C22.6523964,19.372682 24,18.067264 24,16.4149847 L24,16.4149847 L24,2.95769731 C24,1.34760364 22.6922388,0 20.9991802,0 L20.9991802,0 L2.99988288,0 C1.34760364,0 0,1.30541807 0,2.95769731 L0,2.95769731 L0,16.4149833 C0,18.025077 1.34760364,19.3726806 2.99988288,19.3726806 L2.99988288,19.3726806 L7.4997072,19.3726806 L7.4997072,23.3090899 Z"></path>
      </svg>
    </>
  );
};
