import React from "react";

export const CultureofficeIcon = ({...props}) => {
  return (
    <>
      <svg viewBox="0 0 24 24">
        <g>
          <path d="M12.017165,1 C12.2671754,1 12.5477993,1.06134942 12.7829404,1.21034127 L12.8963685,1.29226246 L23.5343099,10.6462341 L23.6393326,10.7450036 C23.8699951,10.9790668 23.9987107,11.2383369 24,11.5228137 L23.9926623,11.6462756 C23.9926623,12.3521873 23.4390061,12.9141486 22.7833034,12.9778191 L22.6592735,12.9838314 L21.3258848,12.9838314 L21.3550538,19.6549422 L21.3502612,19.824331 C21.2701536,21.1686915 20.1976984,22.2449932 18.8571844,22.3289464 L18.6882764,22.3342196 L5.337722,22.3342196 L5.16905218,22.3289649 C3.83042386,22.2453075 2.75932153,21.1728513 2.67577515,19.8358879 L2.67052746,19.6674422 L2.67052746,12.9838314 L1.33547227,12.9838314 L1.20471264,12.9778191 C0.51748417,12.9141486 3.55271368e-15,12.3521873 3.55271368e-15,11.6462756 C3.55271368e-15,11.318137 0.0958346404,11.0219007 0.315362694,10.7575668 L0.417100432,10.6462341 L11.1004603,1.33401351 L11.2116302,1.23575044 C11.4748248,1.03196662 11.7619461,1 12.017165,1 Z M16.3340113,10.3337211 C15.4549972,9.45470706 14.0558435,9.41475188 13.129544,10.2138555 L13.0005395,10.3337211 L12.0004979,11.3337626 L11.0004564,10.3337211 L10.8714518,10.2138555 C9.94515233,9.41475188 8.54599858,9.45470706 7.66698455,10.3337211 C6.78797051,11.2127351 6.74801533,12.6118889 7.547119,13.5381883 L7.66698455,13.6671929 L11.5296455,17.5298539 L11.6118154,17.5996066 C11.8424912,17.7649463 12.1585046,17.7649463 12.3891804,17.5996066 L12.4713503,17.5298539 L16.3340113,13.6671929 L16.4538768,13.5381883 C17.2529805,12.6118889 17.2130253,11.2127351 16.3340113,10.3337211 Z"></path>
        </g>
      </svg>
    </>
  );
};