//import Player from "@/components/Player";
import LinkStyled from "@/components/LinkStyled";
import { useCookieConsent } from "@/context/CookieConsentProvider";
import { nextTrackState } from "@/context/states";
import { basierCirleBold, basierCirleRegular } from "@/styles/fonts";
import theme from "@/styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Portal, Slide } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { grey } from "@mui/material/colors";
import dynamic from "next/dynamic";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";

const Player = dynamic(() => import("@/components/Player"));

export default function PlayerBar() {
  const [nextTrack, setNextTrack] = useRecoilState(nextTrackState);
  const { cookieConsent, update: updateCookieConsent } =
    useCookieConsent("soundcloud");
  const [preservedNextTrack, setPreservedNextTrack] = useState(null);
  const [showBar, setShowBar] = useState(false);

  useEffect(() => {
    if (nextTrack) {
      setPreservedNextTrack({ ...nextTrack });
      setShowBar(true);
    }
  }, [nextTrack]);

  const handleConsent = () => {
    updateCookieConsent("soundcloud", true);
    setNextTrack(preservedNextTrack);
    //setPlayerCommand({command: 'play'});
  };

  const handleClose = () => {
    setShowBar(false);
  };

  return (
    <>
      <Slide direction="up" in={showBar}>
        <AppBar position="fixed" sx={PlayerStyles} className="player">
          {!cookieConsent ? (
            <Box className="soundcloud-hint">
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item xs={12} sm={10} md={7}>
                  <Typography variant="body1">
                    Deine Zustimmung für Cookies ist erforderlich um den Player
                    benutzen zu können.{" "}
                    <LinkStyled href="/legal/impressum" label="Mehr erfahren" />
                  </Typography>
                </Grid>
                <Grid item xs={12} md="auto" className="soundcloud-button">
                  <Button
                    onClick={handleConsent}
                    variant="contained"
                    color="secondary"
                  >
                    Zustimmung jetzt erteilen
                  </Button>
                </Grid>
                <Grid item md="auto">
                  <Box className="soundcloud-hint-close">
                    <Button
                      onClick={handleClose}
                      variant="contained"
                      size="large"
                      aria-label="Schließen"
                    >
                      <CloseIcon fontSize="inherit" />
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Player cookieConsent={cookieConsent} />
          )}
        </AppBar>
      </Slide>
      {showBar && (
        <Portal>
          <AppBar
            sx={{
              backgroundColor: theme.palette.secondary.dark,
              position: "unset",
              minHeight: "3.4375rem",
              [theme.breakpoints.up("md")]: {
                minHeight: "4rem",
              },
            }}
          />
        </Portal>
      )}
    </>
  );
}

const PlayerStyles = {
  backgroundColor: theme.palette.common.black,
  borderTop: "0.3125rem solid",
  borderTopColor: grey[700],
  top: "auto",
  bottom: 0,

  "& .soundcloud-hint": {
    zIndex: "3500",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    minHeight: "calc(100% + 7.5rem)",
    transform: "translateY(-7.5rem)",
    backgroundColor: theme.palette.common.black,
    padding: "1.5rem",
    borderTop: grey[700],
    borderTopWidth: "0.0625rem",
    borderTopStyle: "solid",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      padding: "0 1.5rem",
      transform: "translateY(-4rem)",
      minHeight: "calc(100% + 4rem)",
    },
    "& .soundcloud-button": {
      textAlign: "right",
      "& .MuiButton-root": {
        width: "100%",
        [theme.breakpoints.up("sm")]: {
          width: "auto",
        },
      },
    },
    "& .MuiTypography-root": {
      fontSize: "0.75rem",
      lineHeight: "1.1rem",
      marginBottom: "0.5rem",
      [theme.breakpoints.up("md")]: {
        fontSize: "0.875rem",
        marginBottom: 0,
      },
    },
  },

  "& .soundcloud-hint-close": {
    position: "absolute",
    top: "0",
    right: "0",
    "& .MuiButton-root": {
      padding: "0.5rem",
      minWidth: "unset",
      width: "2.5rem",
      [theme.breakpoints.down("md")]: {
        borderRadius: "50%",
        backgroundColor: "transparent",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "1.5rem",
      },
    },
    [theme.breakpoints.up("md")]: {
      position: "unset",
      transform: "unset",
      "& .MuiButton-root": {
        minWidth: "unset",
        width: "2.875rem",
      },
    },
  },

  "& .player-wrapper": {
    paddingTop: "0.5rem",
    alignItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      paddingTop: "0",
      alignItems: "center",
    },
  },

  "& .player": {
    "&--song": {
      position: "relative",
      maxWidth: "14rem",
      overflow: "hidden",
      whiteSpace: "nowrap",
      [theme.breakpoints.up("md")]: {
        maxWidth: "16rem",
      },
      [theme.breakpoints.up("xl")]: {
        maxWidth: "24rem",
      },

      "& .marquee-container": {
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&::after": {
          zIndex: "100",
          position: "absolute",
          top: "0",
          right: "0",
          width: "100%",
          height: "100%",
          content: "''",
          background:
            "linear-gradient(90deg, rgba(26,26,26,1) 0%, rgba(26,26,26,0) 10%, rgba(26,26,26,0) 90%, rgba(26,26,26,1) 100%)",
        },
      },

      "& .MuiTypography-root": {
        textTransform: "uppercase",
      },

      "&__artist": {
        fontFamily: basierCirleRegular.style.fontFamily,
      },
      "&__tracktitle": {
        fontFamily: basierCirleBold.style.fontFamily,
        fontWeight: basierCirleBold.style.fontWeight,
      },
    },

    "&--songtime": {
      display: "none",
      overflow: "hidden",
      [theme.breakpoints.up("lg")]: {
        display: "block",
      },
      "& .MuiTypography-root": {
        textAlign: "right",
      },
      "&__totaltime": {
        color: grey[700],
      },
      "& > .MuiGrid-root": {
        width: "6rem",
      },
    },

    "&--controls": {
      "&__mobile": {
        marginLeft: "0.5rem",
        [theme.breakpoints.up("sm")]: {
          marginLeft: "0",
        },
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
        "& .MuiSvgIcon-root": {
          fontSize: 18,
        },
      },
    },

    "&--icon": {
      "&__small": {
        fontSize: "1.5rem",
      },
      "&__large": {
        fontSize: 44,
      },
    },

    "&--image": {
      zIndex: "-500",
      position: "absolute",
      top: "0",
      left: "0",
      width: "8.4375rem",
      height: "4.75rem",
      [theme.breakpoints.up("lg")]: {
        position: "relative",
      },
      [theme.breakpoints.up("md")]: {
        width: "6.875rem",
        height: "3.875rem",
      },
      "&::after": {
        zIndex: "500",
        content: "''",
        position: "absolute",
        left: "0",
        top: "0",
        height: "100%",
        width: "100%",
        background:
          "linear-gradient(90deg, rgba(26,26,26,0.5) 0%, rgba(26,26,26,1) 100%);",
        [theme.breakpoints.up("lg")]: {
          display: "none",
        },
      },
    },

    "&--functionbuttons": {
      "& .MuiIconButton-root": {
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: grey[700],
        borderRadius: "unset",
        "&:hover": {
          backgroundColor: theme.palette.common.white,
          borderColor: theme.palette.common.white,
          "& .MuiSvgIcon-root": {
            color: grey[700],
          },
        },
        "&:active": {
          opacity: ".75",
        },
        "& .MuiSvgIcon-root": {
          fontSize: "1rem",
          color: theme.palette.common.white,
        },
      },

      "&__fullview": {
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },

      "&__playlist": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "block",
        },
      },

      "&__more": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "block",
        },
      },
    },
  },

  "& .MuiToolbar-root": {
    minHeight: "unset",
    height: "4.75rem",
    pl: 0,
    pr: 1,
    [theme.breakpoints.up("md")]: {
      height: "3.75rem",
    },
  },
  "& .MuiLinearProgress-root": {
    top: "-5px",
  },
  "& .MuiSvgIcon-root": {
    color: theme.palette.common.white,
  },
};
