import React from "react";

export const SpotifyIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path
          d="M12,22 C17.520161,22 22,17.520161 22,12 C22,6.47983896 17.520161,2 12,2 C6.47983896,2 2,6.47983896 2,12 C2,17.520161 6.47983896,22 12,22 Z M18.3951603,10.9959686 C18.1854824,10.9959686 18.0564509,10.9435479 17.8749993,10.8387096 C15.004031,9.12500049 9.870967,8.71370968 6.5483866,9.64112903 C6.40322507,9.68145161 6.22177346,9.74596799 6.02822495,9.74596799 C5.495967,9.74596799 5.08870869,9.33064467 5.08870869,8.79435484 C5.08870869,8.2459675 5.42741874,7.93548436 5.79032197,7.83064541 C7.2096773,7.41532209 8.79838673,7.21774292 10.5282258,7.21774292 C13.4717742,7.21774292 16.5564516,7.83064541 18.8104836,9.14516178 C19.1249993,9.3266134 19.3306447,9.57661327 19.3306447,10.0564524 C19.3306447,10.6048397 18.8870963,10.9959686 18.3951603,10.9959686 Z M17.1451603,14.0685479 C16.935483,14.0685479 16.794354,13.9758065 16.6491937,13.8991926 C14.1290324,12.4072571 10.370968,11.8064509 7.02822568,12.7137089 C6.83467717,12.7661278 6.72983822,12.8185479 6.5483866,12.8185479 C6.11693511,12.8185479 5.76612817,12.467741 5.76612817,12.0362901 C5.76612817,11.6048392 5.97580547,11.318548 6.39112817,11.2016128 C7.51209604,10.8870965 8.65725708,10.6532254 10.3346767,10.6532254 C12.9516119,10.6532254 15.4798382,11.3024192 17.4717735,12.4879022 C17.7983866,12.6814501 17.9274181,12.9314506 17.9274181,13.2822576 C17.9233856,13.717741 17.5846762,14.0685479 17.1451603,14.0685479 Z M16.0604837,16.7137107 C15.891129,16.7137107 15.7862913,16.6612906 15.6290323,16.5685479 C13.1129035,15.0524186 10.1854839,14.9879022 7.29435496,15.5806447 C7.13709653,15.6209672 6.93145112,15.6854836 6.81451527,15.6854836 C6.42338636,15.6854836 6.17741837,15.3749993 6.17741837,15.0483873 C6.17741837,14.6330653 6.42338636,14.4354836 6.72580571,14.3709685 C10.0282254,13.6411295 13.4032247,13.7056459 16.2822579,15.4274206 C16.5282259,15.5846784 16.6733875,15.7258074 16.6733875,16.0927432 C16.6733875,16.4596789 16.3870969,16.7137107 16.0604837,16.7137107 Z"
        ></path>
      </svg>
    </>
  );
};

