import LinkStyled from "@/components/LinkStyled";
import { useRouter } from "next/router";

export const NavigationMobileMenuItem = ({ label, href }) => {
  const router = useRouter();

  return (
    <LinkStyled
      href={href}
      label={label}
      variant={`navlink${
        router.pathname.indexOf(href) === 0 ? "__is-active" : ""
      }`}
    />
  );
};
