import React from "react";

export const PhoneIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M20.5714286,24 C22.4651789,24 24,22.4651789 24,20.5714286 L24,3.42857143 C24,1.5348211 22.4624993,0 20.5714286,0 L3.42857143,0 C1.5348211,0 0,1.5348211 0,3.42857143 L0,20.5714286 C0,22.4651789 1.5348211,24 3.42857143,24 L20.5714286,24 Z M17.3967868,18.8571429 C10.6414294,18.8571429 5.14500128,13.360714 5.14500128,6.60000065 C5.14500128,6.20742907 5.41200175,5.8719635 5.79375213,5.78464181 L8.49268096,5.16160583 C8.88610922,5.07037354 9.28982408,5.27458845 9.45321628,5.64707075 L10.6998237,8.55492783 C10.8454841,8.89730317 10.7471164,9.29582078 10.4591806,9.53099932 L9.01607241,10.7142857 C9.92625128,12.5683921 11.4342867,14.0758929 13.2894655,14.9866071 L14.4691078,13.5449998 C14.7018217,13.2561967 15.1039295,13.1566069 15.4462501,13.304785 L18.3519639,14.5503208 C18.7232151,14.7107146 18.9267859,15.1178578 18.8357154,15.5089286 L18.2132149,18.2083936 C18.125733,18.5900901 17.7900009,18.8571429 17.3967868,18.8571429 Z"></path>
      </svg>
    </>
  );
};
