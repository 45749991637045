import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";

export const StyledBurger = styled(Box)`
  width: 2rem;
  height: 1.5rem;
  position: relative;
  transform: rotate(0deg);
  overflow: hidden;

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    opacity: 1;
    right: 0;
    background: ${({theme, open}) =>
      open ? theme.palette.common.white : theme.palette.common.white};
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;

    :nth-of-type(1) {
      top: ${({open}) => (open ? "10px" : "0")};
      width: ${({open}) => (open ? "0%" : "100%")};
      right: ${({open}) => (open ? "50%" : "0")};
    }

    :nth-of-type(2),
    :nth-of-type(3) {
      top: 10px;
    }

    :nth-of-type(4) {
      top: 24px;
    }

    :nth-of-type(2) {
      transform: ${({open}) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-of-type(3) {
      transform: ${({open}) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }

    :nth-of-type(4) {
      top: ${({open}) => (open ? "10px" : "20px")};
      width: ${({open}) => (open ? "0%" : "100%")};
      right: ${({open}) => (open ? "50%" : "0")};
    }
  }
`;