import React from "react";

export const MailIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M20.5714286,24 C22.466786,24 24,22.466786 24,20.5714286 L24,3.42857143 C24,1.53375026 22.4678568,0 20.5714286,0 L3.42857225,0 C1.53375026,0 0,1.53375026 0,3.42857143 L0,20.5714286 C0,22.466786 1.53321402,24 3.42857143,24 L20.5714286,24 Z M11.6191071,12.5635725 L3.42857143,8.46964318 L3.42857143,7.71428571 C3.42857143,6.76767949 4.1962501,6 5.14285714,6 L18.8571429,6 C19.8037507,6 20.5714286,6.76767949 20.5714286,7.71428571 L20.5714286,8.46964318 L12.3857141,12.5635725 C12.1446427,12.6841082 11.8600715,12.6841082 11.6191071,12.5635725 Z M18.8571429,18 L5.14285714,18 C4.1962501,18 3.42857143,17.2323221 3.42857143,16.2857143 L3.42857143,10.3874997 L10.8482143,14.0983919 C11.2125002,14.2767857 11.6035718,14.3678578 12,14.3678578 C12.3964282,14.3678578 12.7901786,14.2774299 13.1501786,14.097483 L20.5714286,10.3874997 L20.5714286,16.2857143 C20.5714286,17.2323221 19.8037507,18 18.8571429,18 Z"></path>
      </svg>
    </>
  );
};
