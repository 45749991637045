import React from "react";

export const LikeIcon = ({}) => {
  return (
      <svg viewBox="0 0 42 42">
          <g fillRule="evenodd">
              <path id="likeicon-border" d="M0,40 L40,40 L40,0 L0,0 L0,40 Z M2,38 L38,38 L38,2 L2,2 L2,38 Z"></path>
              <path id="likeicon-heart" d="M19.9953122,30.5 C20.3858747,30.5 20.7776561,30.3468595 21.0739064,30.0404382 L30.1254692,20.6795015 C32.7593756,17.9609375 32.6140614,13.4656253 29.6609378,10.9465618 C28.5687494,9.9649992 27.1671872,9.45781279 25.7515631,9.5 C23.9942198,9.5 22.2204685,10.2218747 20.9234381,11.5615616 L20,12.5117187 L19.0765626,11.5615616 C17.7781253,10.2218747 16.0062501,9.5 14.2484376,9.5 C12.8328121,9.5 11.432656,9.96546841 10.2987497,10.9465618 C7.38593721,13.4679685 7.24015594,17.9637499 9.86609364,20.6778135 L18.9223435,30.0387516 C19.2218747,30.3453131 19.6062503,30.5 19.9953122,30.5 Z M20,27.9171886 L11.4846873,19.1140628 C9.97343707,17.5531251 9.6153121,14.4734378 11.7715621,12.647656 C13.641875,11.0285931 16.2054682,11.8273435 17.4621875,13.1243753 L20.0014062,15.7456245 L22.5401564,13.1243753 C23.7785945,11.8460932 26.3492193,11.0201559 28.2307811,12.647656 C30.3640628,14.4968753 30.0546875,17.5203128 28.5125003,19.1140628 L20,27.9171886 Z" fillRule="nonzero"></path>
          </g>
      </svg>
  );
};
