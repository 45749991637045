import { ImageAsset } from "@/components/ImageAsset";
import {
  BulletinboardIcon,
  DashboardIcon,
  DashboardUserAvatar,
  LogoutIcon,
  MessageIcon,
  RotateIcon,
  SettingsIcon,
  UsereditIcon,
} from "@/components/icons";
import AppContext from "@/context/AppContext";
import { useBandsForUserProfile, useRole, useUnreadMessages } from "@/lib/api";
import { logout } from "@/lib/auth";
import { responsiveImage } from "@/lib/helper";
import { basierCirleBold, basierCirleRegular } from "@/styles/fonts";
import theme from "@/styles/theme";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { Skeleton, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import { grey } from "@mui/material/colors";
import Link from "next/link";
import Router from "next/router";
import { useContext } from "react";

export const DashboardMenu = ({ onClick }) => {
  const { user, profile, band, setBand, setUser } = useContext(AppContext);
  const { role, isLoading: isRolesLoading } = useRole(user);
  const { memberships, isLoading, isError } = useBandsForUserProfile(
    profile?.id,
  );
  const { count: countUnseenMessages } = useUnreadMessages();

  const handleClick = (event) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleChangeToProfile = (event) => {
    setBand(null);
    if (onClick) {
      onClick(event);
    }
    Router.push("/secure");
  };

  const handleChangeToBand = (band) => {
    setBand(band);
    if (onClick) {
      onClick(band);
    }
    Router.push("/secure");
  };

  const handleLogout = (event) => {
    logout();
    setBand(null);
    setUser(null);
  };

  if (isLoading) {
    return <Skeleton />;
  }

  const style = {
    "&.MuiBox-root": {
      backgroundColor: grey[900],
      borderColor: grey[600],
      borderStyle: "solid",
      borderWidth: "2px",
      boxShadow: "rgba(0, 0, 0, 0.3) 0px 10px 12px",
      display: "flex",
      flexDirection: "column",
      "& .MuiButton-root": {
        justifyContent: "flex-start",
        color: theme.palette.common.white,
        fontSize: ".875rem",
        textTransform: "uppercase",
        lineHeight: "1.2",
        fontFamily: basierCirleRegular.style.fontFamily,
        fontWeight: basierCirleRegular.style.fontWeight,
        "&:hover": {
          background:
            "linear-gradient(90deg, rgba(33,33,33,0) 0%, rgba(25,25,25,1) 25%)",
          color: theme.palette.primary.main,
          "& svg": {
            fill: theme.palette.primary.main,
          },
        },
        "& svg": {
          height: "1.25rem",
          fill: theme.palette.common.white,
        },
        "& .notification": {
          backgroundColor: theme.palette.secondary.main,
          padding: ".25rem",
          marginLeft: "auto",
          borderRadius: "9999px",
          minWidth: "1.25rem",
          textAlign: "center",
          "&.MuiTypography-root": {
            fontSize: ".75rem",
            lineHeight: ".75rem",
          },
        },
      },
      "& .MuiAccordion-root": {
        border: "none",
        backgroundColor: "unset",
        boxShadow: "none",
        fontSize: ".875rem",
        margin: "0",
        ".Mui-expanded": {
          "& .MuiButton-root": {
            color: theme.palette.primary.main,
            "& svg": {
              fill: theme.palette.primary.main,
            },
          },
        },
        "& .MuiAccordionSummary-root": {
          padding: "0",
          minHeight: "unset",
          "& .MuiAccordionSummary-content": {
            margin: "0",
          },
        },
      },
      "& .MuiAccordionDetails-root": {
        position: "relative",
        margin: ".25rem 0",
        padding: "0",
        borderTopWidth: "1px",
        borderTopStyle: "solid",
        borderTopColor: grey[800],
        fontWeight: basierCirleRegular.style.fontWeight,
      },
      "& .accordion": {
        "&--inactive": {
          "&:hover": {
            "& .MuiButton-root": {
              color: theme.palette.primary.main,
            },
          },
          "& .MuiButton-root": {
            paddingTop: "0.5rem",
            paddingBottom: "0.25rem",
            color: grey[600],
          },
          "&__name": {
            whiteSpace: "nowrap",
            maxWidth: "16rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginLeft: ".5rem",
            "&.MuiTypography-root": {
              fontSize: ".875rem",
            },
            [theme.breakpoints.up("sm")]: {
              maxWidth: "30rem",
            },
            [theme.breakpoints.up("lg")]: {
              maxWidth: "32rem",
            },
          },
          "&__image": {
            marginLeft: "-.25rem",
            width: "1.5rem",
            height: "1.5rem",
            "& img": {
              borderRadius: "9999px",
            },
          },
          "&__initials": {
            marginLeft: "-.25rem",
            width: "1.5rem",
            height: "1.5rem",
            "& img": {
              width: "1.5rem",
              height: "1.5rem",
            },
          },
          "&__notification": {
            transform: "translateX(-1rem)",
            position: "absolute",
            top: "0",
            right: "0",
            bottom: "0",
            marginTop: "auto",
            marginBottom: "auto",
            backgroundColor: grey[800],
            padding: ".25rem",
            borderRadius: "9999px",
            minWidth: "1.25rem",
            height: "1.25rem",
            textAlign: "center",
            "&.MuiTypography-root": {
              fontSize: ".75rem",
              lineHeight: ".75rem",
            },
          },
        },
      },
      "& .profile": {
        justifyContent: "flex-start",
        width: "100%",
        backgroundColor: "#373737",
        marginBottom: ".75rem",
        "&:hover": {
          backgroundColor: grey[800],
        },
        "& .MuiTypography-root": {
          textTransform: "uppercase",
          lineHeight: "1.2",
        },
        "&--wrapper": {
          padding: ".5rem",
        },
        "&--image": {
          width: "2.125rem",
          height: "2.125rem",
          "& img": {
            borderRadius: "9999px",
          },
        },
        "&--caption": {
          "&.MuiTypography-root": {
            fontSize: ".625rem",
            color: grey[500],
          },
        },
        "&--name": {
          "&.MuiTypography-root": {
            color: theme.palette.common.white,
            fontSize: ".875rem",
            fontFamily: basierCirleBold.style.fontFamily,
            fontWeight: basierCirleBold.style.fontWeight,
          },
        },
      },
      "& .notifications": {
        "& .MuiBadge-badge": {
          display: countUnseenMessages !== 0 ? "" : "none",
          transform: "scale(1) translate(-1rem, 0.8125rem)",
        },
      },
    },
  };

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <Box sx={style}>
          {band && (
            <ButtonBase
              component={Link}
              href={`/musik/${band.slug}`}
              onClick={handleClick}
              className="profile"
            >
              <Box className="profile--wrapper">
                <Grid container columnSpacing={1} alignItems="center">
                  <Grid item xs="auto">
                    <Box className="profile--image">
                      <ImageAsset
                        url={responsiveImage(band.photo?.formats, 102)}
                        layout="fixed"
                        width={34}
                        height={34}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className="profile--caption">Profil</Typography>
                    <Typography className="profile--name">
                      {band ? (
                        <>
                          {band?.name.length > 32
                            ? band?.name.slice(0, 32) + "..."
                            : band?.name}
                        </>
                      ) : (
                        <>{profile.name}</>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </ButtonBase>
          )}

          {!band && (
            <ButtonBase
              component={Link}
              href="/secure"
              onClick={handleClick}
              className="profile"
            >
              <Box className="profile--wrapper">
                <Grid container columnSpacing={1} alignItems="center">
                  <Grid item xs="auto">
                    <Box className="profile--image">
                      <DashboardUserAvatar dashboardMenu />
                    </Box>
                  </Grid>
                  <Grid item xs="auto">
                    <Typography className="profile--caption">Profil</Typography>
                    <Typography className="profile--name">
                      {band ? band?.name : profile?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </ButtonBase>
          )}

          <Button
            component={Link}
            href="/secure"
            fullWidth
            startIcon={<DashboardIcon />}
            onClick={handleClick}
          >
            Dashboard
          </Button>
          <Badge
            badgeContent={countUnseenMessages}
            color="secondary"
            className="notifications"
          >
            <Button
              component={Link}
              href="/secure/chat"
              fullWidth
              startIcon={<MessageIcon />}
              onClick={handleClick}
            >
              Messenger
            </Button>
          </Badge>
          {role?.type === "admin" && (
            <Button
              href="/secure/admin/newsletter"
              component={Link}
              fullWidth
              startIcon={<ForwardToInboxIcon />}
              onClick={handleClick}
            >
              Bands kontaktieren
            </Button>
          )}
          <Button
            href="/secure/board"
            component={Link}
            fullWidth
            startIcon={<BulletinboardIcon />}
            onClick={handleClick}
          >
            Schwarzes Brett
          </Button>
          {band && (
            <Button
              href="/secure/bands/edit"
              component={Link}
              fullWidth
              startIcon={<UsereditIcon />}
              onClick={handleClick}
            >
              Profil bearbeiten
            </Button>
          )}
          {memberships?.length > 0 && (
            <Accordion
              sx={{
                maxHeight: "14.5rem",
                overflowY: "scroll",
                scrollbarWidth: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "&.MuiPaper-root": {
                  width: "100%",
                },
              }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Button fullWidth startIcon={<RotateIcon />}>
                  Profil wechseln
                </Button>
              </AccordionSummary>
              {band && (
                <AccordionDetails className="accordion--inactive">
                  <Button fullWidth onClick={handleChangeToProfile}>
                    <Grid container alignItems="center">
                      <Grid
                        item
                        xs="auto"
                        className="accordion--inactive__initials"
                      >
                        <DashboardUserAvatar dashboardMenu />
                      </Grid>
                      <Grid item xs="auto">
                        <Typography className="accordion--inactive__name">
                          {profile.name?.length > 0 ? profile.name : user.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </AccordionDetails>
              )}
              {memberships?.map((b) => {
                return (
                  band?.id !== b.band.id && (
                    <AccordionDetails
                      key={b.band.id}
                      className="accordion--inactive"
                    >
                      <Button
                        fullWidth
                        onClick={() => {
                          handleChangeToBand(b.band);
                        }}
                      >
                        <Grid container alignItems="center">
                          <Grid
                            item
                            xs="auto"
                            className="accordion--inactive__image"
                          >
                            <ImageAsset
                              url={responsiveImage(b.band.photo?.formats, 72)}
                              layout="responsive"
                              width={24}
                              height={24}
                            />
                          </Grid>
                          <Grid item xs="auto">
                            <Typography className="accordion--inactive__name">
                              {b.band.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Button>
                    </AccordionDetails>
                  )
                );
              })}
            </Accordion>
          )}
          <Button
            href="/secure/settings"
            component={Link}
            fullWidth
            startIcon={<SettingsIcon />}
            onClick={handleClick}
          >
            Einstellungen
          </Button>
          <Button onClick={handleLogout} fullWidth startIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
