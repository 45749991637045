import {
  basierCirleBold,
  basierCirleRegular,
  futuraNextCondensedExtraBold,
} from "@/styles/fonts";
import { common, grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

const colors = {
  white: "#f7f7f7",
  black: "#1a1a1a",
  primaryMain: "#F49C2E",
  secondaryMain: "#7E69EE",
};

const theme = createTheme({
  palette: {
    type: "light",
    common: {
      white: colors.white,
      black: colors.black,
    },
    primary: {
      main: colors.primaryMain,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondaryMain,
      dark: "#3f3283",
    },
    warning: {
      main: colors.primaryMain,
      dark: "#ffb25e",
    },
    error: {
      main: "#c33132",
      dark: "#c33232",
      light: "#e57373",
    },
    success: {
      main: "#76e573",
      dark: "#4bce47",
    },
    background: {
      default: colors.black,
    },
  },
  typography: {
    fontFamily: basierCirleRegular.style.fontFamily,
    h1: {
      fontSize: "2.125rem",
      color: common.white,
      textTransform: "uppercase",
      lineHeight: "1.2",
      fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
      "@media (min-width:600px)": {
        fontSize: "4rem",
      },
    },
    h2: {
      color: common.white,
      textTransform: "uppercase",
      lineHeight: "1.2",
      fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
    },
    h3: {
      fontSize: "1.5rem",
      lineHeight: "1.2",
      color: common.white,
      textTransform: "uppercase",
      fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
      "@media (min-width:600px)": {
        fontSize: "2.25rem",
      },
    },
    h4: {
      fontSize: "1.875rem",
      lineHeight: "1.2",
      color: common.white,
      textTransform: "uppercase",
      fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
      "@media (min-width:600px)": {
        fontSize: "1.875rem",
      },
    },
    h5: {
      color: common.white,
      textTransform: "uppercase",
      lineHeight: "1.2",
      fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
    },
    h6: {
      color: common.white,
      fontSize: "1.125rem",
      textTransform: "uppercase",
      lineHeight: "1.2",
      fontFamily: basierCirleRegular.style.fontFamily,
      "@media (min-width:600px)": {
        fontSize: "1.375rem",
      },
    },
    subtitle1: {
      color: common.white,
      fontSize: "1.375rem",
      lineHeight: "1.2",
      "@media (min-width:600px)": {
        fontSize: "1.875rem",
        lineHeight: "1.4",
      },
    },
    subtitle2: {
      color: common.white,
      fontSize: ".9375rem",
      lineHeight: "1.3",
      "@media (min-width:600px)": {
        fontSize: "1.375rem",
        lineHeight: "1.4",
      },
    },
    body1: {
      fontSize: "1rem",
      lineHeight: "1.2",
      "@media (min-width:600px)": {
        fontSize: "1.125rem",
        lineHeight: "1.425rem",
      },
    },
    body2: {
      fontSize: ".9375rem",
      lineHeight: "1.125rem",
      "@media (min-width:600px)": {
        fontSize: "1.125rem",
        lineHeight: "1.425rem",
      },
    },
    caption: {
      fontSize: ".75rem",
      lineHeight: "1.2",
      "@media (min-width:600px)": {
        fontSize: ".875rem",
      },
    },
    mapLocationName: {
      fontSize: "1rem",
      fontFamily: futuraNextCondensedExtraBold.style.fontFamily,
      marginBottom: "0.25rem",
      textTransform: "uppercase",
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          whiteSpace: "initial",
          marginBottom: ".25rem",
          display: "block",
          textAlign: "left",
          textTransform: "uppercase",
          fontSize: ".875rem!important",
          fontFamily: basierCirleBold.style.fontFamily,
          fontWeight: basierCirleBold.style.fontWeight,
          color: colors.black,
          "& span": {
            color: "#828282",
            fontWeight: basierCirleRegular.style.fontWeight,
            fontFamily: basierCirleRegular.style.fontFamily,
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          fontFamily: basierCirleRegular.style.fontFamily,
          fontWeight: basierCirleRegular.style.fontWeight,
          fontSize: "1rem !important",
          lineHeight: "1rem !important",
          color: colors.black,
          borderRadius: "0",
          backgroundColor: "#e2e2e2",
          "& .Mui-disabled": {
            backgroundColor: "#F2F2F2",
          },
          "&::before": {
            zIndex: "500",
            content: "''",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            borderStyle: "solid",
            borderWidth: "2px !important",
          },
          "& ::placeholder": {
            opacity: "1",
            color: "#adadad",
            textTransform: "uppercase",
            fontSize: "1rem",
          },
          "& input": {
            padding: ".6875rem 1rem !important",
            height: "unset",
          },
          "&.MuiInputBase-root": {
            "&:hover::before": {
              borderColor: "transparent",
            },
            "&::before": {
              borderColor: "transparent",
            },
            "&::after": {
              borderColor: "transparent",
            },
          },
          "& .MuiSelect-select": {
            fontSize: "1rem !important",
            padding: ".8125rem 1rem",
            minHeight: "unset !important",
            textTransform: "uppercase",
            "&:focus": {
              backgroundColor: "unset",
            },
          },
          "&.MuiInputBase-multiline": {
            padding: ".6875rem 1rem .75rem 1rem !important",
            minHeight: "5rem",
            lineHeight: "1.25 !important",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: "1rem",
          border: "none",
          width: "100%",
          "& ::placeholder": {
            color: colors.black,
            opacity: "1!important",
            textTransform: "uppercase",
            fontSize: "1rem",
          },
          "& :focus::placeholder": {
            opacity: "0.5!important",
          },
          "& input": {
            padding: ".6875rem 1rem !important",
          },
          "&.MuiInputBase-root": {
            "&:hover::before": {
              border: "none",
            },
            "&::before": {
              border: "none",
            },
            "&::after": {
              border: "none",
            },
          },
        },
      },
      variants: [
        {
          props: { variant: "dark" },
          style: {
            backgroundColor: colors.white,
          },
        },
        {
          props: { variant: "light" },
          style: {
            backgroundColor: "#e2e2e2",
          },
        },
      ],
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          "& .MuiLinearProgress-barColorError": {
            backgroundColor: "#e57373",
          },
        },
      },
      variants: [
        {
          props: { variant: "dark" },
          style: {
            backgroundColor: colors.white,
          },
        },
        {
          props: { variant: "light" },
          style: {
            backgroundColor: "#e2e2e2",
          },
        },
      ],
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& svg": {
            fill: "#e2e2e2",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
      },
      variants: [
        {
          props: { variant: "category--Kulturbüros" },
          style: {
            backgroundColor: "#ef7f69",
            "&::before": {
              maskImage: "url(/img/cultureoffice-square.svg)",
              backgroundColor: "#fff",
            },
          },
        },
        {
          props: { variant: "locations-wrapper" },
          style: {
            backgroundColor: "#E2E2E2",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          },
        },
        {
          props: { variant: "map-popup" },
          style: {
            padding: "1rem",
            textAlign: "center",
            backgroundColor: "#EEEEEE",
            "& .MuiTypography-root": {
              textAlign: "center",
              color: colors.black,
              "&.location-name": {
                marginTop: "1rem",
              },
              "&.location-address": {
                marginTop: "0.5rem",
              },
            },
            "& .MuiButton-root": {
              marginTop: "1rem",
              width: "100%",
            },
            "& .MuiChip-root": {
              letterSpacing: "1px",
              padding: "0.5rem",
              borderRadius: "50",
              textTransform: "uppercase",
            },
          },
        },
        {
          props: { variant: "map-location-description" },
          style: {
            backgroundColor: "#E2E2E2",
            padding: "2rem",
            height: "100%",
            "& .title": {
              fontFamily: basierCirleBold.style.fontFamily,
              fontWeight: basierCirleBold.style.fontWeight,
              textAlign: "center",
              textTransform: "uppercase",
              fontSize: "0.875rem",
            },
            "& .value": {
              textAlign: "center",
            },
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: colors.black,
          padding: ".625rem 1rem",
          borderRadius: ".1875rem",
          marginBottom: "1rem",
          lineHeight: "1.4rem",
          display: "flex",
          alignItems: "center",
          "& .MuiAlert-icon": {
            display: "none",
          },
          "& .MuiAlert-message": {
            paddingTop: "0",
            paddingBottom: "0",
            overflow: "unset",
            "& .MuiAlertTitle-root": {
              fontSize: "1.25rem",
              fontFamily: basierCirleBold.style.fontFamily,
              fontWeight: basierCirleBold.style.fontWeight,
            },
            "& strong": {
              fontFamily: basierCirleBold.style.fontFamily,
              fontWeight: basierCirleBold.style.fontWeight,
            },
          },
          "&.MuiAlert-standardError": {
            backgroundColor: "#c33132",
            color: "#fff",
          },
          "&.MuiAlert-standardSuccess": {
            "& .MuiAlert-message": {
              marginRight: "0.75rem",
            },
            backgroundColor: "#76e573",
            color: colors.black,
            "& .MuiButtonBase-root": {
              color: colors.black,
            },
            "& .MuiAlert-icon": {
              color: colors.black,
            },
          },
          "&.MuiAlert-standardInfo": {
            backgroundColor: "#e5e1fc",
            color: colors.secondaryMain,
          },
          "&.MuiAlert-standardWarning": {
            backgroundColor: "#fdebd5",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          transition: "all .2s ease-in-out",
          "&:link": {
            color: colors.secondaryMain,
            textDecoration: "none",
          },
          "&:visited": {
            color: colors.secondaryMain,
          },
          "&:hover": {
            color: colors.secondaryMain,
            opacity: ".75",
          },
          "&:active": {
            color: colors.secondaryMain,
            opacity: "1",
          },
        },
      },
      variants: [
        {
          props: { variant: "white" },
          style: {
            "&:link": {
              color: colors.white,
              textDecoration: "none",
            },
            "&:visited": {
              color: colors.white,
            },
            "&:hover": {
              color: colors.white,
              opacity: ".75",
            },
            "&:active": {
              color: colors.white,
              opacity: "1",
            },
          },
        },
        {
          props: { variant: "black" },
          style: {
            "&:link": {
              color: colors.black,
              textDecoration: "none",
            },
            "&:visited": {
              color: colors.black,
            },
            "&:hover": {
              color: colors.primaryMain,
              opacity: "1",
            },
            "&:active": {
              color: colors.black,
              opacity: "1",
            },
          },
        },
        {
          props: { variant: "primary" },
          style: {
            "&:link": {
              color: colors.primaryMain,
              textDecoration: "none",
            },
            "&:visited": {
              color: colors.primaryMain,
            },
            "&:hover": {
              color: "#7f69ef",
              opacity: "0.75",
            },
            "&:active": {
              color: "#7f69ef",
              opacity: "0.75",
            },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            "&:link": {
              color: "#7f69ef",
              textDecoration: "none",
            },
            "&:visited": {
              color: "#7f69ef",
            },
            "&:hover": {
              color: "#7f69ef",
              opacity: "0.75",
            },
            "&:active": {
              color: "#7f69ef",
              opacity: "0.75",
            },
          },
        },
        {
          props: { variant: "dashboard-notification__link" },
          style: {
            "&:link": {
              color: colors.black,
              textDecoration: "underline",
              textDecorationColor: colors.black,
            },
            "&:visited": {
              color: colors.black,
              textDecoration: "underline",
              textDecorationColor: colors.black,
            },
            "&:hover": {
              color: colors.black,
              opacity: "0.75",
              textDecoration: "underline",
              textDecorationColor: colors.black,
            },
            "&:active": {
              color: colors.black,
              opacity: "0.75",
              textDecoration: "underline",
              textDecorationColor: colors.black,
            },
          },
        },
        {
          props: { variant: "navlink" },
          style: {
            "&:link": {
              textTransform: "uppercase",
              color: colors.white,
              textDecoration: "none",
            },
            "&:visited": {
              color: colors.white,
            },
            "&:hover": {
              color: colors.primaryMain,
              opacity: "1",
            },
            "&:active": {
              color: colors.primaryMain,
              opacity: ".75",
            },
          },
        },
        {
          props: { variant: "navlink__is-active" },
          style: {
            "&:link": {
              textTransform: "uppercase",
              color: colors.primaryMain,
              textDecoration: "none",
            },
            "&:visited": {
              color: colors.primaryMain,
            },
            "&:hover": {
              color: colors.primaryMain,
              opacity: "0.75",
            },
            "&:active": {
              color: colors.primaryMain,
              opacity: ".75",
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          padding: "0.5rem",
          letterSpacing: "1px",
          textTransform: "uppercase",
        },
      },
      variants: [
        {
          props: { variant: "category--Kulturbüros" },
          style: {
            backgroundColor: "#ef7f69",
            "&::before": {
              maskImage: "url(/img/cultureoffice-square.svg)",
              backgroundColor: "#fff",
            },
          },
        },
        {
          props: { variant: "category--Create Music Stützpunkt" },
          style: {
            zIndex: "500",
            backgroundColor: "#009de0",
            "&::before": {
              maskImage: "url(/img/createmusicvenue-square.svg)",
              backgroundColor: "#fff",
            },
          },
        },
        {
          props: { variant: "category--Festivals" },
          style: {
            backgroundColor: "#d769ef",
            "&::before": {
              background: "url(/img/festival-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Hochschulen" },
          style: {
            backgroundColor: "#4fcf73",
            "&::before": {
              background: "url(/img/university-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Labels" },
          style: {
            backgroundColor: "#daca1d",
            "&::before": {
              background: "url(/img/label-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Musikschulen" },
          style: {
            backgroundColor: "#e76a6a",
            "&::before": {
              background: "url(/img/musicschool-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Musikverlage" },
          style: {
            backgroundColor: "#25bf8e",
            "&::before": {
              background: "url(/img/musicpublisher-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Plattenvertriebe" },
          style: {
            backgroundColor: "#e44cc2",
            "&::before": {
              background: "url(/img/recordsale-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Proberaumzentren" },
          style: {
            backgroundColor: "#9679df",
            "&::before": {
              background: "url(/img/rehearsalspace-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Studios" },
          style: {
            backgroundColor: "#f7cf54",
            "&::before": {
              background: "url(/img/studio-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Venues" },
          style: {
            backgroundColor: "#7f69ef",
            "&::before": {
              background: "url(/img/venue-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Vereine & Initiativen" },
          style: {
            backgroundColor: "#f59d31",
            "&::before": {
              background: "url(/img/club-square.svg)",
            },
          },
        },
        {
          props: { variant: "category--Agenturen" },
          style: {
            backgroundColor: "#0088ff",
            "&::before": {
              background: "url(/img/agency-square.svg)",
            },
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: ".875rem",
          lineHeight: "1rem",
          borderRadius: "0px",
          boxShadow: "none",
          letterSpacing: "0",
          fontFamily: basierCirleBold.style.fontFamily,
          fontWeight: basierCirleBold.style.fontWeight,
          padding: ".8125rem 1rem",
          "@media (min-width:600px)": {
            fontSize: "1rem",
          },
          "&:hover": {
            boxShadow: "none",
            backgroundColor: colors.primaryMain,
          },
        },
        outlined: {
          borderWidth: "2px",
          borderColor: colors.secondaryMain,
          "&:hover": {
            borderWidth: "2px",
            borderColor: colors.primaryMain,
            color: colors.primaryMain,
            backgroundColor: "transparent",
          },
        },
        outlinedSizeSmall: {
          minWidth: "unset",
          borderWidth: "2px",
          borderRadius: "3px",
          borderStyle: "solid",
          fontSize: ".75rem !important",
          padding: ".25rem .3125rem",
          borderColor: colors.primaryMain,
          color: colors.primaryMain,
          "& .MuiButton-startIcon": {
            marginRight: "unset",
          },
          "&:hover": {
            borderColor: colors.primaryMain,
            color: "#fff",
            backgroundColor: colors.primaryMain,
          },
        },
        containedSizeSmall: {
          minWidth: "unset",
          borderWidth: "2px",
          borderRadius: "3px",
          borderStyle: "solid",
          fontSize: ".75rem !important",
          padding: ".25rem .3125rem !important",
          borderColor: colors.primaryMain,
          color: "#fff",
          "& .MuiButton-startIcon": {
            marginRight: "unset",
          },
          "&:hover": {
            borderColor: colors.primaryMain,
            color: "#fff",
            backgroundColor: colors.primaryMain,
          },
        },
        outlinedPrimary: {
          borderColor: colors.primaryMain,
          "&:hover": {
            borderColor: "#7E69EE !important",
            color: "#7E69EE !important",
          },
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            padding: ".8125rem 1rem",
          },
        },
        {
          props: { variant: "navbutton__is-active" },
          style: {
            color: colors.secondaryMain,
            "&:hover": {
              opacity: "0.8",
            },
          },
        },
        {
          props: { variant: "contact-link" },
          style: {
            padding: "unset",
            border: "none",
            color: colors.secondaryMain,
            textTransform: "none",
            fontWeight: basierCirleRegular.style.fontWeight,
            fontFamily: basierCirleRegular.style.fontFamily,
            fontSize: ".75rem",
            textAlign: "left",
            lineHeight: "1rem",
            "@media (min-width:600px)": {
              fontSize: "1rem",
              lineHeight: "1.2rem",
            },
            "& svg": {
              transition: "all .2s ease-in-out",
              fill: colors.secondaryMain,
              width: "1rem",
            },
            "&:hover": {
              color: colors.primaryMain,
              backgroundColor: "transparent",
              "& svg": {
                fill: colors.primaryMain,
              },
            },
          },
        },
        {
          props: { variant: "outlined-secondary" },
          style: {
            padding: ".6875rem 1rem",
            border: "2px solid #7E69EE",
            color: colors.secondaryMain,
            borderWidth: "2px",
            borderColor: colors.secondaryMain,
            backgroundColor: "transparent",
            "&:hover": {
              borderColor: colors.primaryMain,
              color: colors.primaryMain,
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "text" },
          style: {
            fontFamily: basierCirleRegular.style.fontFamily,
            fontWeight: basierCirleRegular.style.fontWeight,
            "&:hover": {
              opacity: "0.75",
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "text-white" },
          style: {
            verticalAlign: "unset",
            display: "inline",
            padding: "0",
            minWidth: "unset",
            textTransform: "none",
            fontFamily: basierCirleRegular.style.fontFamily,
            fontWeight: basierCirleRegular.style.fontWeight,
            fontSize: "1rem",
            lineHeight: "1.2rem",
            textDecoration: "underline",
            transition: "all .2s ease-in-out",
            "@media (min-width:600px)": {
              fontSize: "1.125rem",
              lineHeight: "1.425rem",
            },
            "&:hover": {
              opacity: "0.75",
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "text-black" },
          style: {
            verticalAlign: "unset",
            display: "inline",
            color: colors.black,
            padding: "0",
            minWidth: "unset",
            textTransform: "none",
            fontFamily: basierCirleRegular.style.fontFamily,
            fontWeight: basierCirleRegular.style.fontWeight,
            fontSize: "1rem",
            lineHeight: "1.2rem",
            textDecoration: "underline",
            transition: "all .2s ease-in-out",
            "@media (min-width:600px)": {
              fontSize: "1.125rem",
              lineHeight: "1.425rem",
            },
            "&:hover": {
              opacity: "0.75",
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "outlined-primary" },
          style: {
            padding: ".6875rem 1rem",
            border: "2px solid white",
            color: colors.primaryMain,
            borderWidth: "2px",
            borderColor: colors.primaryMain,
            backgroundColor: "transparent",
            "&.MuiButton-outlined-primarySizeSmall": {
              fontSize: ".75rem",
              borderRadius: ".1875rem",
              padding: ".25rem .375rem",
              lineHeight: ".875rem",
            },
            "&:hover": {
              borderColor: colors.secondaryMain,
              color: colors.secondaryMain,
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "outlined-white" },
          style: {
            padding: ".6875rem 1rem",
            border: "2px solid white",
            color: "white",
            borderWidth: "2px",
            borderColor: "white",
            "&:hover": {
              borderWidth: "2px",
              borderColor: "white",
              color: colors.primaryMain,
              backgroundColor: "white",
            },
          },
        },
        {
          props: { variant: "outlined-white-x" },
          style: {
            padding: ".6875rem 1rem",
            border: "2px solid white",
            color: "white",
            borderWidth: "2px",
            borderColor: "white",
            "&.MuiButton-root::after": {
              position: "absolute",
              top: "0",
              right: "0",
              width: "8rem",
              height: "4.91625rem",
              marginRight: "-3rem",
              marginTop: "-1.25rem",
              zIndex: "-1",
              content: "url(/img/line-08-orange.svg)",
            },
            "&:hover": {
              borderWidth: "2px",
              borderColor: "white",
              color: colors.primaryMain,
              backgroundColor: "white",
            },
          },
        },
        {
          props: { variant: "outlined-secondary-small" },
          style: {
            minWidth: "unset",
            borderRadius: "3px",
            borderStyle: "solid",
            fontSize: ".75rem !important",
            padding: ".25rem .3125rem",
            border: "2px solid #7E69EE",
            color: colors.secondaryMain,
            borderColor: colors.secondaryMain,
            backgroundColor: "transparent",
            "&:hover": {
              borderColor: colors.primaryMain,
              color: colors.primaryMain,
              backgroundColor: "transparent",
            },
          },
        },
        {
          props: { variant: "filter--createmusicvenues__selected" },
          style: {
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
            backgroundColor: "#009de0",
            border: "2px solid transparent",
            "&:hover": {
              backgroundColor: "#027db3",
            },
          },
        },
        {
          props: { variant: "filter--createmusicvenues__inactive" },
          style: {
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
            backgroundColor: "transparent",
            border: "2px solid #009de0",
            "&.MuiButton-root.MuiButtonBase-root": {
              color: "#009de0",
            },
            "& .MuiButton-startIcon": {
              "& path": {
                fill: "#009de0",
              },
            },
            "&:hover": {
              backgroundColor: "#e6f5ff",
              border: "2px solid #027db3",
            },
          },
        },
        {
          props: { variant: "filter--venues__selected" },
          style: {
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
            backgroundColor: "#7f69ef",
            border: "2px solid transparent",
            "&:hover": {
              backgroundColor: "#5e4fb3",
            },
          },
        },
        {
          props: { variant: "filter--venues__inactive" },
          style: {
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
            backgroundColor: "transparent",
            border: "2px solid #7f69ef",
            "&.MuiButton-root.MuiButtonBase-root": {
              color: "#7f69ef",
            },
            "& .MuiButton-startIcon": {
              "& path": {
                fill: "#7f69ef",
              },
            },
            "&:hover": {
              backgroundColor: "#eae5ff",
              border: "2px solid #5e4fb3",
            },
          },
        },
        {
          props: { variant: "filter--clubs__selected" },
          style: {
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
            color: "#fff",
            backgroundColor: "#f59d31",
            border: "2px solid transparent",
            "&:hover": {
              backgroundColor: "#cc8328",
            },
          },
        },
        {
          props: { variant: "filter--clubs__inactive" },
          style: {
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
            backgroundColor: "transparent",
            border: "2px solid #f59d31",
            "&.MuiButton-root.MuiButtonBase-root": {
              color: "#f59d31",
            },
            "& .MuiButton-startIcon": {
              "& path": {
                fill: "#f59d31",
              },
            },
            "&:hover": {
              backgroundColor: "#fff4e5",
              border: "2px solid #cc8328",
            },
          },
        },
        {
          props: { variant: "filter--cultureoffice__selected" },
          style: {
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
            backgroundColor: "#ef7f69",
            border: "2px solid transparent",
            "&:hover": {
              backgroundColor: "#b35f4e",
            },
          },
        },
        {
          props: { variant: "filter--cultureoffice__inactive" },
          style: {
            paddingLeft: ".5rem",
            paddingRight: ".5rem",
            backgroundColor: "transparent",
            border: "2px solid #ef7f69",
            "&.MuiButton-root.MuiButtonBase-root": {
              color: "#ef7f69",
            },
            "& .MuiButton-startIcon": {
              "& path": {
                fill: "#ef7f69",
              },
            },
            "&:hover": {
              backgroundColor: "#ffeae5",
              border: "2px solid #b35f4e",
            },
          },
        },
      ],
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&.MuiButtonGroup-contained": {
            "& .MuiButtonBase-root": {
              "&.MuiIconButton-root": {
                height: "2.75rem",
                width: "2.75rem",
                borderRadius: "0",
                border: "unset",
                "&.MuiIconButton-colorPrimary": {
                  backgroundColor: colors.primaryMain,
                },
                "&.MuiIconButton-colorSecondary": {
                  backgroundColor: colors.secondaryMain,
                },
                "& svg": {
                  fill: "#fff",
                },
              },
            },
          },
        },
      },
      variants: [
        {
          props: { variant: "locations-bands-toggle" },
          style: {
            margin: "1rem",
            "& .MuiButton-root": {
              padding: "0.25rem 0.5rem",
              color: colors.secondaryMain,
              border: "2px",
              borderColor: colors.secondaryMain,
              borderStyle: "solid",
              height: "1.75rem",
              fontSize: ".75rem",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: colors.primaryMain,
                borderColor: colors.primaryMain,
                color: colors.white,
              },
              "&.locations-active": {
                backgroundColor: colors.secondaryMain,
                color: colors.white,
                "&:hover": {
                  borderColor: colors.secondaryMain,
                },
              },
              "&.bands-active": {
                backgroundColor: colors.secondaryMain,
                color: colors.white,
                "&:hover": {
                  borderColor: colors.secondaryMain,
                },
              },
            },
          },
        },
      ],
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          fontSize: ".875rem",
          color: "#7e7e7e",
          marginTop: "1.5rem",
          marginBottom: "1.5rem",
          borderColor: "#777777",
          "&:before": {
            borderTopColor: "#777777",
          },
          "&:after": {
            borderTopColor: "#777777",
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: "82px",
        },
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "helperText" },
          style: {
            color: "#828282",
            fontSize: ".75rem",
            lineHeight: "1.2",
            marginTop: ".1857rem",
            "@media (min-width:600px)": {
              fontSize: ".875rem",
            },
          },
        },
        {
          props: { variant: "helperText--error" },
          style: {
            color: "#c33132",
            fontSize: ".75rem",
            lineHeight: "1.2",
            marginTop: ".1857rem",
            "@media (min-width:600px)": {
              fontSize: ".875rem",
            },
          },
        },
      ],
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          "&.MuiContainer-maxWidthXl": {
            maxWidth: "1564px",
          },
        },
      },
      variants: [
        {
          props: { variant: "hero" },
          style: {
            marginTop: "3rem",
          },
        },
      ],
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "CardFeatureTeaser" },
          style: {
            bgcolor: colors.secondaryMain,
            color: "white",
            height: "11.5rem",
            position: "relative",
            "& img": {
              filter: "grayscale(100%)",
            },
            "& .MuiCardActionArea-root": {
              height: "100%",
              background:
                "linear-gradient(0deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 100%), linear-gradient(0deg, rgba(126,105,238,0.86) 0%, rgba(126,105,238,0.86) 100%)",
              "&:hover": {
                background:
                  "linear-gradient(0deg, rgba(0,0,0,0.46) 0%, rgba(0,0,0,0) 100%), linear-gradient(0deg, rgba(244,156,46,0.86) 0%, rgba(244,156,46,0.86) 100%)",
                "& .MuiSvgIcon-root": {
                  color: colors.primaryMain,
                },
              },
            },
            "& .MuiCardActionArea-focusHighlight": {
              backgroundColor: "transparent",
            },
            "& .MuiCardContent-root": {
              textTransform: "uppercase",
              "& .MuiTypography-root": {
                lineHeight: "1",
              },
            },
          },
        },
        {
          props: { variant: "PlaylistSmallSongPlay" },
          style: {
            "&.MuiCard-root": {
              transition: "all .2s ease-in-out",
              backgroundColor: colors.primaryMain,
              border: "2px solid #F49C2E",
              "& .MuiTypography-root": {
                color: colors.white,
              },
              "& .MuiSvgIcon-root": {
                color: colors.white,
              },
              "& .functionbuttons": {
                "& .MuiIconButton-root": {
                  borderColor: "#f7f7f7 !important",
                  "&:hover": {
                    backgroundColor: colors.white,
                    "& .MuiSvgIcon-root": {
                      color: colors.primaryMain,
                    },
                  },
                },
              },
              "& .PlaylistListCard--IconMore": {
                backgroundColor: colors.primaryMain,
              },
            },
          },
        },
        {
          props: { variant: "MusicExploreCard" },
          style: {
            backgroundColor: colors.secondaryMain,
            "@media (min-width:600px)": {
              "& .MusicExploreCard--Gradientoverlay": {
                "&::after": {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: "''",
                  opacity: "0",
                  transition: "all .2s ease-in-out",
                  background:
                    "linear-gradient(0deg, rgba(63,50,131,0.85) 0%, rgba(126,105,238,0.85) 100%)",
                },
              },
              "&.MuiCard-root": {
                "&:hover": {
                  backgroundColor: "#6d5acc",
                  transform: "translateY(-.625rem)",
                  boxShadow: "rgb(88, 85, 102) 0px 30px 30px -20px",
                  "& .MusicExploreCard--Gradientoverlay": {
                    "&::after": {
                      opacity: "1",
                      background:
                        "linear-gradient(0deg, rgba(63,50,131,0.85) 0%, rgba(126,105,238,0.85) 100%)",
                    },
                  },
                },
              },
            },
            "& .MuiCardActionArea-root": {
              "& .MusicExploreCard--Badge": {
                backgroundColor: colors.secondaryMain,
                "&::after": {
                  borderTop: "solid .53125rem #463889",
                  borderRight: "solid .01875rem #463889",
                },
              },
              "& .MuiSvgIcon-root": {
                transition: "all .2s ease-in-out",
              },
              ":hover": {
                "& .MuiSvgIcon-root": {
                  color: colors.primaryMain,
                },
              },
            },
            "& .MuiButton-root": {
              "&:hover": {
                color: colors.secondaryMain,
              },
            },
          },
        },
        {
          props: { variant: "MusicExploreCardPlay" },
          style: {
            backgroundColor: colors.primaryMain,
            "& img": {
              filter: "grayscale(100%)",
            },
            "&.MuiCard-root": {
              transform: "translateY(-.625rem)",
              boxShadow: "rgb(111, 71, 20) 0px 30px 30px -20px",
            },
            "@media (min-width:600px)": {
              "&.MuiCard-root": {
                "&:hover": {
                  backgroundColor: "#d0862a",
                  transform: "translateY(-.625rem)",
                },
              },
            },
            "& .MuiCardActionArea-root": {
              "& .MusicExploreCard--Badge": {
                backgroundColor: colors.primaryMain,
                "&::after": {
                  borderTop: "solid .53125rem #5a3a12",
                  borderRight: "solid .01875rem #5a3a12",
                },
              },
              "& .MusicExploreCard--Gradientoverlay::after": {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                content: "''",
                opacity: "1",
                background:
                  "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.25) 100%), linear-gradient(0deg, rgba(244,156,46,0.86) 0%, rgba(244,156,46,0.86) 100%)",
                transition: "all .2s ease-in-out",
              },
              "& .MuiSvgIcon-root": {
                color: colors.primaryMain,
              },
            },
            "& .MuiButton-root": {
              "&:hover": {
                color: colors.primaryMain,
              },
            },
          },
        },
        {
          props: { variant: "PlaylistListCard" },
          style: {
            "&.MuiCard-root": {
              "@media only screen and (max-width: 599px)": {
                border: "2px solid #7E69EE",
              },
            },
            "@media (min-width:600px)": {
              backgroundColor: colors.secondaryMain,
              "& .MuiCardActionArea-root": {
                "& .MuiBox-root::after": {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: "''",
                  opacity: "0",
                  background:
                    "linear-gradient(0deg, rgba(63,50,131,0.85) 0%, rgba(126,105,238,0.85) 100%)",
                  transition: "all .2s ease-in-out",
                },
                "&:hover": {
                  "& .MuiSvgIcon-root": {
                    color: "#F49C2E!important",
                  },
                },
              },
              "&:hover": {
                backgroundColor: "#6d5acc",
                "& :nth-of-type(3).MuiSvgIcon-root": {
                  color: colors.secondaryMain,
                },
                "& .MuiCardActionArea-root": {
                  "& .MuiBox-root::after": {
                    opacity: "1",
                    background:
                      "linear-gradient(0deg, rgba(63,50,131,0.85) 0%, rgba(126,105,238,0.85) 100%)",
                  },
                },
              },
            },
            "& .PlaylistListCard--Bandname": {
              "@media (min-width:600px)": {
                "& .MuiLink-root": {
                  "&:link": {
                    color: colors.white,
                  },
                  "&:visited": {
                    color: colors.white,
                  },
                  "&:hover": {
                    color: colors.primaryMain,
                  },
                  "&:active": {
                    color: colors.white,
                    opacity: "1",
                  },
                },
              },
            },
            "& .MuiIconButton-root": {
              "&:hover": {
                color: colors.secondaryMain,
              },
            },
            "& .PlaylistListCard--IconMore": {
              backgroundColor: colors.secondaryMain,
            },
          },
        },
        {
          props: { variant: "PlaylistListCardPlay" },
          style: {
            "&.MuiCard-root": {
              transition: "all .2s ease-in-out",
              backgroundColor: colors.primaryMain,
              "@media only screen and (max-width: 600px)": {
                border: "2px solid #F49C2E",
              },
            },
            "@media (min-width:600px)": {
              border: "none",
              backgroundColor: colors.primaryMain,
              "& .MuiCardActionArea-root": {
                "& .MuiBox-root::after": {
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: "''",
                  opacity: "1",
                  transition: "all .2s ease-in-out",
                  background:
                    "linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0.25) 100%), linear-gradient(0deg, rgba(244,156,46,0.86) 0%, rgba(244,156,46,0.86) 100%)",
                },
                ":hover": {
                  "& .MuiSvgIcon-root": {
                    color: colors.primaryMain,
                  },
                },
              },
              "&:hover": {
                backgroundColor: "#d0862a",
              },
            },
            "& .MuiCardActionArea-root": {
              "& .MuiSvgIcon-root": {
                color: colors.primaryMain,
              },
            },
            "& .MuiIconButton-root": {
              "&:hover": {
                color: colors.primaryMain,
              },
            },
            "& .PlaylistListCard--IconMore": {
              backgroundColor: colors.primaryMain,
            },
            "& .PlaylistListCard--SongTitle": {
              color: colors.white,
            },
            "& .PlaylistListCard--Bandname": {
              "& a": {
                "&:link": {
                  color: colors.white,
                  textDecoration: "none",
                },
                "&:visited": {
                  color: colors.white,
                },
                "&:hover": {
                  color: colors.primaryMain,
                },
                "&:active": {
                  color: colors.white,
                  opacity: "1",
                },
              },
            },
            "& .PlaylistListCard--PlayIcon .MuiSvgIcon-root": {
              color: colors.white,
            },
          },
        },
        {
          props: { variant: "PlaylistGridCard" },
          style: {
            "&.MuiCard-root": {
              "& .PlaylistGridCard--GradientOverlay::before": {
                position: "absolute",
                content: "''",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.25) 100%)",
                zIndex: "30",
              },
              "& .PlaylistGridCard--GradientOverlay::after": {
                position: "absolute",
                content: "''",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                opacity: "0",
                zIndex: "30",
                transition: "all .2s ease-in-out",
                background:
                  "linear-gradient(0deg, rgba(63,50,131,0.85) 0%, rgba(126,105,238,0.85) 100%)",
              },
              "&:hover": {
                "& .PlaylistGridCard--GradientOverlay::after": {
                  opacity: "1",
                  background:
                    "linear-gradient(0deg, rgba(63,50,131,0.85) 0%, rgba(126,105,238,0.85) 100%)",
                },
                "& .PlaylistGridCard--PlayIcon": {
                  "& .MuiSvgIcon-root": {
                    color: colors.primaryMain,
                  },
                },
              },
            },
            "& .PlaylistGridCard--Genre": {
              color: colors.secondaryMain,
            },
            "& .MuiIconButton-root": {
              "&:hover": {
                color: colors.secondaryMain,
              },
            },
            "& .PlaylistGridCard--Bandname": {
              "& .MuiLink-root": {
                "&:hover": {
                  color: colors.primaryMain,
                  opacity: "1",
                },
              },
            },
            "& .PlaylistGridCard--BandnameWrapper:hover + .PlaylistGridCard--IconWrapper > .PlaylistGridCard--PlayIcon > .MuiSvgIcon-root":
              {
                color: colors.secondaryMain,
              },
            "& .PlaylistGridCard--IconMore": {
              backgroundColor: colors.secondaryMain,
            },
          },
        },
        {
          props: { variant: "PlaylistGridCardPlay" },
          style: {
            "&.MuiCard-root": {
              "& .PlaylistGridCard--GradientOverlay::before": {
                position: "absolute",
                content: "''",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(0deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0.25) 100%)",
                zIndex: "30",
                opacity: "1",
              },
              "& .PlaylistGridCard--GradientOverlay::after": {
                position: "absolute",
                content: "''",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                opacity: "0",
                zIndex: "30",
                transition: "all .2s ease-in-out",
                background:
                  "linear-gradient(0deg, rgba(243,156,45,0.5) 0%, rgba(243,156,45,0.85) 100%)",
              },
              "&:hover": {
                "& .PlaylistGridCard--PlayIcon": {
                  "& .MuiSvgIcon-root": {
                    color: colors.primaryMain,
                  },
                },
              },
            },
            "& .PlaylistGridCard--Bandname": {
              "& a": {
                "&:link": {
                  color: colors.white,
                  textDecoration: "none",
                },
                "&:visited": {
                  color: colors.white,
                },
                "&:hover": {
                  color: colors.primaryMain,
                },
                "&:active": {
                  color: colors.white,
                  opacity: "1",
                },
              },
            },
            "& .PlaylistGridCard--Genre": {
              color: colors.primaryMain,
            },
            "& .MuiIconButton-root": {
              "&:hover": {
                color: colors.primaryMain,
              },
            },
            "& .PlaylistGridCard--IconMore": {
              backgroundColor: colors.primaryMain,
            },
          },
        },
        {
          props: { variant: "LocationCard-White" },
          style: {
            "&.MuiCard-root": {
              backgroundColor: "#fff",
            },
          },
        },
        {
          props: { variant: "InboxTopicCard" },
          style: {
            "&.MuiCard-root": {
              position: "relative",
              "&::after": {
                content: "''",
                background: "url(/img/inbox--border__right.svg)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
              },
              backgroundColor: colors.secondaryMain,
              "& .MuiTypography-root": {
                color: "#fff",
              },
            },
          },
        },
        {
          props: { variant: "InboxTopicCard--selected" },
          style: {
            "&.MuiCard-root": {
              position: "relative",
              "&::after": {
                content: "''",
                background: "url(/img/inbox--border__right.svg)",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right",
                width: "1.5rem",
                height: "100%",
                position: "absolute",
                top: "0",
                bottom: "0",
                right: "0",
              },
              backgroundColor: colors.secondaryMain,
              "& .MuiTypography-root": {
                color: "#fff",
              },
            },
          },
        },
        {
          props: { variant: "InboxTopicCard--unread" },
          style: {
            "&.MuiCard-root": {
              overflow: "visible",
              position: "relative",
              "& span": {
                fontWeight: basierCirleBold.style.fontWeight,
                fontFamily: basierCirleBold.style.fontFamily,
                color: colors.primaryMain,
              },
              "&::after": {
                //content: "''",
                position: "absolute",
                width: ".875rem",
                height: ".875rem",
                borderRadius: "1rem",
                backgroundColor: colors.primaryMain,
                right: "0",
                top: "0",
                bottom: "0",
                marginTop: "auto",
                marginBottom: "auto",
                transform: "translate(-.625rem)",
                "@media (min-width:600px)": {
                  width: "1rem",
                  height: "1rem",
                  transform: "translateX(1rem)",
                },
              },
            },
          },
        },
        {
          props: { variant: "ChatMessageCard--me" },
          style: {
            "& .chatmessage-wrapper": {
              flexDirection: "row",
              "@media (min-width:600px)": {
                flexDirection: "row-reverse",
              },
            },
            "& .chatmessage": {
              "&--img": {
                marginTop: "1.5rem",
                "@media (min-width:600px)": {
                  display: "unset",
                },
              },
              "&--message": {
                "& .MuiBox-root": {
                  display: "flex",
                  flexWrap: "wrap",
                  width: "100%",
                  justifyContent: "flex-start",
                  "@media (min-width:600px)": {
                    justifyContent: "flex-end",
                  },
                  "& .MuiTypography-root": {
                    backgroundColor: colors.black,
                    color: colors.white,
                  },
                },
              },
            },
          },
        },
      ],
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          background: "transparent",
          padding: "0",
          marginBottom: ".5rem",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          textTransform: "initial",
          color: "#828282",
          marginLeft: "0",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          textTransform: "uppercase",
          minHeight: "2.5rem",
          "& .Mui-disabled": {
            backgroundColor: "#cccbcb",
            color: colors.black,
            "& input": {
              cursor: "not-allowed",
            },
          },
        },
      },
      variants: [
        {
          props: { variant: "filled" },
          style: {
            background: common.white,
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "& .Mui-disabled": {
            pointerEvents: "all !important",
            cursor: "not-allowed",
          },
        },
      },
      variants: [
        {
          props: { variant: "active" },
          style: {
            opacity: "1",
          },
        },
        {
          props: { variant: "inactive" },
          style: {
            opacity: ".5",
          },
        },
        {
          props: { variant: "outlined-grey" },
          style: {
            transition: "all .2s ease-in-out",
            border: "2px solid #e2e2e2 !important",
            "& .MuiSvgIcon-root": {
              fill: "#989898 !important",
            },
            "&:hover": {
              borderColor: `${colors.primaryMain} !important`,
              backgroundColor: "transparent",
              "& .MuiSvgIcon-root": {
                fill: `${colors.primaryMain} !important`,
              },
            },
            "&.Mui-disabled": {
              pointerEvents: "all !important",
              cursor: "not-allowed !important",
              "&:hover": {
                border: "2px solid #e2e2e2 !important",
                "& .MuiSvgIcon-root": {
                  fill: "#989898 !important",
                },
              },
            },
          },
        },
        {
          props: { variant: "PlayerMenuIcon-active" },
          style: {
            borderColor: "#F49C2E!important",
            "& .MuiSvgIcon-root": {
              color: "#F49C2E!important",
            },
          },
        },
        {
          props: { variant: "square-secondary" },
          style: {
            width: "2.625rem",
            height: "2.625rem",
            backgroundColor: colors.secondaryMain,
            borderRadius: "0",
            "&:hover": {
              backgroundColor: colors.primaryMain,
            },
            "& svg": {
              fill: colors.white,
            },
          },
        },
      ],
    },
    MuiStep: {
      variants: [
        {
          props: { variant: "MuiStep-active" },
          style: {
            "&.MuiStep-root": {
              "&::before": {
                backgroundColor: "#7E69EE !important",
              },
            },
            "& .MuiStepLabel-root": {
              scale: "1.25",
              "& .MuiSvgIcon-root": {
                borderColor: "#7E69EE !important",
                "& text": {
                  fill: "#7E69EE !important",
                },
              },
              "& .MuiStepLabel-label": {
                color: "#7f69ef !important",
              },
            },
          },
        },
      ],
    },
    MuiSlider: {
      variants: [
        {
          props: { variant: "AgeSlider" },
          style: {
            width: "calc(100% - 1rem)",
            "& .MuiSlider-thumb": {
              transition: "all .2s ease-in-out",
              backgroundColor: "#e2e2e2",
              border: "1px solid #fbfbfb",
              "&::before": {
                boxShadow: "none",
              },
              "&:hover": {
                backgroundColor: "currentColor",
                border: "1px solid currentColor",
              },
            },
            "& .MuiSlider-rail": {
              transform: "translate(-0.5rem, -50%)",
              width: "calc(100% + 1rem)",
              color: "#d8d8d8",
            },
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: colors.black,
        },
      },
    },
    MuiMenuItem: {
      variants: [
        {
          props: { variant: "PlayerPlayListMenuItemPlay" },
          style: {
            "& .MuiTypography-root": {
              color: "#F49C2E !important",
            },
            "& .MuiSvgIcon-root": {
              color: "#F49C2E !important",
            },
          },
        },
      ],
    },
    MuiMenu: {
      variants: [
        {
          props: { variant: "dropdown" },
          style: {
            "& .MuiPaper-root": {
              overflow: "visible",
              mt: 1.5,
              zIndex: "100",
              backgroundColor: grey[900],
              borderColor: grey[600],
              borderStyle: "solid",
              borderWidth: "2px",
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 10px 12px",
            },
            "& .MuiMenuItem-root": {
              transition: "all .2s ease-in-out",
              "&:hover": {
                "& .MuiTypography-root": {
                  color: colors.white,
                },
                "& path": {
                  fill: colors.primaryMain,
                },
              },
            },
            "& .MuiListItemText-root": {
              "& .MuiTypography-root": {
                color: grey[50],
                fontSize: "0.875rem",
              },
            },
          },
        },
      ],
    },
    MuiSkeleton: {
      variants: [
        {
          props: { variant: "textfield" },
          style: {
            height: "2.625rem",
          },
        },
      ],
    },
  },
});
export default theme;
