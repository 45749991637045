import { AlertStyled } from "@/components/AlertStyled";
import CheckboxStyled from "@/components/CheckboxStyled";
import LinkStyled from "@/components/LinkStyled";
import TextFieldStyled from "@/components/TextFieldStyled";
import AppContext from "@/context/AppContext";
import { login } from "@/lib/auth";
import theme from "@/styles/theme";
import { Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { grey } from "@mui/material/colors";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MenuList from "@mui/material/MenuList";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import React, { useContext, useEffect, useState } from "react";
import { basierCirleBold } from "@/styles/fonts";
import { TextFieldPasswordStyled } from "@/components/TextFieldPasswordStyled";

export const NavigationLogin = ({ open, setOpen, onLogin, setAnchorEl }) => {
  const [error, setError] = useState(false);
  const DEFAULT_VALUES = {
    username: "",
    password: "",
  };
  const appContext = useContext(AppContext);
  const [formValues, setFormValues] = useState(DEFAULT_VALUES);

  useEffect(() => {
    if (!open) {
      // was closed
      if (navigator.userAgent.toLowerCase().indexOf("safari") === -1) {
        document.querySelector("#login-username").focus();
      }
    }
  }, [open]);

  const handleInputChanged = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleToggle = () => {
    if (setOpen && typeof setOpen === "function") {
      setOpen((prevOpen) => !prevOpen);
    } else {
      setAnchorEl(null);
    }
  };

  function handleListKeyDown(event) {
    if (event.key === "Escape") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();

    login(formValues.username, formValues.password, formValues.remember)
      .then((res) => {
        appContext.setUser(res.data.user);
        appContext.setBand(null);
        if (onLogin) {
          onLogin(res.data.user);
        }
      })
      .catch((error) => {
        setError(error?.data[0]?.messages[0]?.message ?? "Fehler beim Login");
      });
  };

  const navigation = {
    "&.navigation": {
      "&--loginmenu": {
        "&__container": {
          mt: ".75rem",
          px: 2,
          py: 1.5,
          width: "300px",
          backgroundColor: grey[900],
          borderColor: grey[600],
          borderStyle: "solid",
          borderWidth: "2px",
          boxShadow: "rgba(0, 0, 0, 0.3) 0px 10px 12px",

          "& .MuiList-root": {
            p: 0,
          },
          "& .MuiInputLabel-root": {
            display: "none",
          },
          "& p.MuiTypography-root": {
            fontWeight: basierCirleBold.style.fontWeight,
            fontFamily: basierCirleBold.style.fontFamily,
            textTransform: "uppercase",
            color: theme.palette.common.white,
            mb: 1,
          },
          "& a.MuiTypography-root": {
            fontSize: ".875rem",
          },
          "& span": {
            color: grey[500],
          },
          "& .MuiDivider-root": {
            mt: 0.5,
            mb: 1,
          },
          "& .forgot-password": {
            margin: ".25rem 0",
            padding: "0",
            "& a": {
              fontSize: ".875rem",
              textTransform: "initial",
            },
          },
        },
      },
    },
  };

  const wrapper = {
    "&.wrapper": {
      "& .MuiFormControl-root": {
        mb: ".375rem",
        backgroundColor: theme.palette.common.white,
      },
      "& .MuiFormGroup-root": {
        mb: ".3125rem",
      },
      "& .MuiBox-root": {
        width: "100%",
        textAlign: "center",
      },
    },
  };

  return (
    <Paper sx={navigation} className="navigation--loginmenu__container">
      <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
        <form onSubmit={handleSubmit}>
          <Container disableGutters={true}>
            <Grid
              container
              alignItems="center"
              justify="center"
              className="wrapper"
              sx={wrapper}
              onKeyDown={(event) => event.stopPropagation()}
            >
              {error && (
                <Grid item xs={12}>
                  <AlertStyled severity="error" text={error} />
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="caption" component="p">
                  Login
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextFieldStyled
                  id="login-username"
                  autoFocus={false}
                  required={true}
                  type="email"
                  label="E-Mail Adresse"
                  name="username"
                  placeholder="E-Mail Adresse"
                  value={formValues.email}
                  onChange={handleInputChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldPasswordStyled
                  required={true}
                  label="Passwort"
                  name="password"
                  placeholder="Passwort"
                  value={formValues.password}
                  onChange={handleInputChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <CheckboxStyled
                  required={false}
                  label="Eingeloggt bleiben"
                  name="remember"
                  onChange={handleInputChanged}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  fullWidth
                  color="secondary"
                  type="submit"
                >
                  Einloggen
                </Button>
                <Box>
                  <Button
                    onClick={handleToggle}
                    className="forgot-password"
                    disableRipple
                  >
                    <LinkStyled
                      href="/request-password-reset"
                      label="Passwort vergessen?"
                    />
                  </Button>
                </Box>
                <Divider />
                <Typography variant="caption" component="p">
                  Neuer Account
                </Typography>
                <Button
                  component={Link}
                  href="/register"
                  variant="contained"
                  fullWidth
                  onClick={handleToggle}
                >
                  Jetzt registrieren
                </Button>
                <Box sx={{ lineHeight: "1.425rem" }}>
                  <Button
                    onClick={handleToggle}
                    className="forgot-password"
                    disableRipple
                  >
                    <LinkStyled
                      href="/netzwerk/ueber-uns"
                      label="Warum bei showcase.nrw registrieren?"
                    />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </form>
      </MenuList>
    </Paper>
  );
};
