import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import SvgIcon from "@mui/material/SvgIcon";
import ArrowDropDownIcon from "./icons/ArrowDropDownIcon";

export default function MoreInformationDropdown({
  anchorEl,
  setAnchorEl,
  children,
}) {
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="outlined-white"
        id="more-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={
          <SvgIcon color="common.white">
            <ArrowDropDownIcon />
          </SvgIcon>
        }
      >
        Mehr informationen
      </Button>
      <Menu
        variant="dropdown"
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "more-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {children}
      </Menu>
    </>
  );
}
