import React from "react";

export const UsereditIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M8.36625013,11.5999999 C11.0174994,11.5999999 13.1662501,9.450875 13.1662501,6.79999994 C13.1662501,4.14912488 11.0175006,2 8.36625013,2 C5.74875005,2 3.59999996,4.14912488 3.59999996,6.79999994 C3.59999996,9.450875 5.74875005,11.5999999 8.36625013,11.5999999 Z M22.1763768,14.9277495 L23.606252,13.4978743 C24.1312497,12.9687499 24.1312497,12.1137503 23.6062497,11.5887498 L22.8150006,10.7971248 C22.2877507,10.2698749 21.4331266,10.2698749 20.9058767,10.7971248 L19.4760015,12.2273741 L22.1763768,14.9277495 Z M13.6044741,21.1926126 L16.2827246,20.6567373 C16.4132994,20.6305177 16.5332998,20.5665121 16.6275373,20.4721624 L21.3262868,15.7734129 L18.6259115,13.0730376 L13.9274996,17.7725 C13.8332243,17.8666998 13.7689875,17.9868124 13.7428492,18.1176126 L13.2073492,20.7954878 C13.1601744,21.0313253 13.3683367,21.2394888 13.6044741,21.1926126 Z M12.0397498,21.1999998 C11.9930247,20.9925871 11.986125,20.7758749 12.0291296,20.5609995 L12.5646296,17.8831244 C12.6374918,17.5185122 12.8149794,17.1871245 13.0776293,16.9246245 L14.7921293,15.2101244 C13.6237494,14.0899996 12.0450003,13.3999999 10.3012503,13.3999999 L6.49875004,13.3999999 C2.91037499,13.3999999 0,16.3137503 0,19.9024996 C0,20.6187498 0.582000153,21.1999998 1.29975012,21.1999998 L12.0397498,21.1999998 Z"></path>
      </svg>
    </>
  );
};
