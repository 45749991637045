import React from "react";

export const BulletinboardIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M18.75,24 C19.9921875,24 21,22.9931245 21,21.75 L21,5.25 C21,4.00687551 19.9921875,3 18.75,3 L16.2243755,3 C15.6046879,1.25624943 13.9546881,0 12,0 C10.0453134,0 8.39531279,1.25624943 7.77656293,3 L5.25,3 C4.0078125,3 3,4.00687551 3,5.25 L3,21.75 C3,22.9921875 4.0078125,24 5.25,24 L18.75,24 Z M12,6 C11.1717188,6 10.5,5.32968807 10.5,4.5 C10.5,3.67171955 11.1703126,3 12,3 C12.8282812,3 13.5,3.67171955 13.5,4.5 C13.5,5.32828045 12.8282812,6 12,6 Z M7.5,13.875 C6.87890625,13.875 6.375,13.3710938 6.375,12.75 C6.375,12.1289062 6.87890625,11.625 7.5,11.625 C8.12109375,11.625 8.625,12.1312494 8.625,12.75 C8.625,13.3687506 8.12343764,13.875 7.5,13.875 Z M17.25,13.5 L11.25,13.5 C10.8374999,13.5 10.5,13.1624994 10.5,12.75 C10.5,12.3375006 10.8374999,12 11.25,12 L17.25,12 C17.6624994,12 18,12.3375006 18,12.75 C18,13.1624994 17.6624994,13.5 17.25,13.5 Z M7.5,18.375 C6.87890625,18.375 6.375,17.8710938 6.375,17.25 C6.375,16.6289062 6.87890625,16.125 7.5,16.125 C8.12109375,16.125 8.625,16.6289062 8.625,17.25 C8.625,17.8710938 8.12343764,18.375 7.5,18.375 Z M17.25,18 L11.25,18 C10.8374999,18 10.5,17.6624994 10.5,17.25 C10.5,16.8375006 10.8374999,16.5 11.25,16.5 L17.25,16.5 C17.6625466,16.5 18,16.8374534 18,17.25 C18,17.6624994 17.6624994,18 17.25,18 Z"></path>
      </svg>
    </>
  );
};
