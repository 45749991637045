import React from "react";

function HomeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M15.996 8.122c0 .5-.418.89-.89.89h-.89l.027 4.45v.668c0 .64-.5 1.113-1.113 1.113h-2.225c-.64 0-1.113-.473-1.113-1.113v-2.448a.915.915 0 00-.89-.89h-1.78c-.501 0-.89.418-.89.89v2.448c0 .64-.501 1.113-1.113 1.113H2.893c-.64 0-1.113-.473-1.113-1.113v-3.115-2.003H.89c-.5 0-.89-.39-.89-.89 0-.25.083-.473.278-.668L7.4 1.25A.837.837 0 018.012 1c.195 0 .417.083.584.223l7.094 6.231c.222.195.334.417.306.668z"
      ></path>
    </svg>
  );
}

export default React.memo(HomeIcon);
