import React from "react";

export const AmazonmusicIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <g
        >
          <g
            transform="translate(2.000000, 6.000000)"
          >
            <path
              d="M13.2932896,5.05527117 C13.1584824,5.14514266 12.9562716,5.21254627 12.709125,5.21254627 C12.3271712,5.21254627 11.9676852,5.16761053 11.6081993,5.05527117 C11.5183278,5.0328033 11.4509241,5.01033543 11.4059884,5.01033543 C11.3385848,5.01033543 11.3161169,5.05527117 11.3161169,5.14514266 L11.3161169,5.36982138 C11.3161169,5.43722499 11.3385848,5.48216074 11.3610527,5.52709648 C11.3835205,5.54956435 11.4284563,5.5945001 11.4958599,5.61696797 C11.8553458,5.77424307 12.2597675,5.84164669 12.709125,5.84164669 C13.1809503,5.84164669 13.5404362,5.72930733 13.8325186,5.50462861 C14.1246009,5.27994989 14.2594081,4.98786755 14.2594081,4.60591373 C14.2594081,4.33629927 14.1920045,4.13408842 14.0571973,3.95434545 C13.9223901,3.79707035 13.6977113,3.63979524 13.3831611,3.52745588 L12.7540607,3.28030929 C12.5069141,3.19043781 12.3271712,3.10056632 12.2597675,3.01069483 C12.169896,2.92082334 12.1249603,2.83095186 12.1249603,2.67367675 C12.1249603,2.33665867 12.3721069,2.1569157 12.8888679,2.1569157 C13.1809503,2.1569157 13.4730326,2.20185144 13.7426471,2.29172293 C13.8325186,2.3141908 13.8999222,2.33665867 13.9223901,2.33665867 C13.9897937,2.33665867 14.0347294,2.29172293 14.0347294,2.20185144 L14.0347294,1.97717272 C14.0347294,1.90976911 14.0122615,1.86483337 13.9897937,1.81989762 C13.9673258,1.77496188 13.9223901,1.75249401 13.8549864,1.73002613 C13.5179684,1.61768677 13.1809503,1.55028316 12.8439322,1.55028316 C12.4170426,1.55028316 12.0575567,1.66262252 11.7879422,1.86483337 C11.5183278,2.06704421 11.3835205,2.35912655 11.3835205,2.69614462 C11.3835205,3.21290568 11.6756029,3.5948595 12.2597675,3.81953822 L12.9338037,4.06668481 C13.1584824,4.1565563 13.2932896,4.22395991 13.3831611,4.3138314 C13.4730326,4.40370289 13.4955005,4.49357437 13.4955005,4.6283816 C13.4955005,4.80812458 13.4280969,4.96539968 13.2932896,5.05527117 Z"
              id="path844"
            ></path>
            <path
              d="M9.76583376,1.81989762 L9.76583376,4.80812458 C9.38387994,5.05527117 9.00192611,5.1900784 8.61997229,5.1900784 C8.3728257,5.1900784 8.19308273,5.12267479 8.08074337,4.98786755 C7.96840401,4.85306032 7.92346827,4.65084948 7.92346827,4.35876714 L7.92346827,1.81989762 C7.92346827,1.70755826 7.87853252,1.66262252 7.76619316,1.66262252 L7.29436785,1.66262252 C7.18202849,1.66262252 7.13709275,1.70755826 7.13709275,1.81989762 L7.13709275,4.60591373 C7.13709275,4.98786755 7.22696424,5.30241776 7.42917508,5.50462861 C7.63138593,5.70683945 7.92346827,5.81917881 8.30542209,5.81917881 C8.82218314,5.81917881 9.33894419,5.63943584 9.83323737,5.27994989 L9.87817312,5.54956435 C9.87817312,5.61696797 9.90064099,5.63943584 9.94557673,5.66190371 C9.96804461,5.68437158 10.0129803,5.68437158 10.080384,5.68437158 L10.417402,5.68437158 C10.5297414,5.68437158 10.5746771,5.63943584 10.5746771,5.52709648 L10.5746771,1.81989762 C10.5746771,1.70755826 10.5297414,1.66262252 10.417402,1.66262252 L9.94557673,1.66262252 C9.8107695,1.66262252 9.76583376,1.73002613 9.76583376,1.81989762 L9.76583376,1.81989762 Z"
              id="path855"
            ></path>
            <path
              d="M5.4969381,5.70683945 L5.96876341,5.70683945 C6.08110277,5.70683945 6.12603852,5.66190371 6.12603852,5.54956435 L6.12603852,2.74108037 C6.12603852,2.35912655 6.03616703,2.06704421 5.83395618,1.86483337 C5.63174533,1.66262252 5.36213087,1.55028316 4.98017705,1.55028316 C4.463416,1.55028316 3.92418707,1.73002613 3.40742602,2.11197996 C3.22768304,1.73002613 2.89066497,1.55028316 2.39637179,1.55028316 C1.9020786,1.55028316 1.40778542,1.73002613 0.913492242,2.06704421 L0.868556499,1.81989762 C0.868556499,1.75249401 0.846088627,1.73002613 0.801152883,1.70755826 C0.778685011,1.68509039 0.733749267,1.68509039 0.688813524,1.68509039 L0.329327574,1.68509039 C0.216988214,1.68509039 0.172052471,1.73002613 0.172052471,1.84236549 L0.172052471,5.57203222 C0.172052471,5.68437158 0.216988214,5.72930733 0.329327574,5.72930733 L0.801152883,5.72930733 C0.913492242,5.72930733 0.958427986,5.68437158 0.958427986,5.57203222 L0.958427986,2.53886952 C1.34038181,2.3141908 1.72233563,2.17938357 2.12675732,2.17938357 C2.35143604,2.17938357 2.50871115,2.24678719 2.59858263,2.38159442 C2.68845412,2.51640165 2.75585774,2.69614462 2.75585774,2.96575909 L2.75585774,5.54956435 C2.75585774,5.66190371 2.80079348,5.70683945 2.91313284,5.70683945 L3.38495815,5.70683945 C3.49729751,5.70683945 3.54223325,5.66190371 3.54223325,5.54956435 L3.54223325,2.78601611 L3.54223325,2.65120888 C3.54223325,2.60627314 3.54223325,2.56133739 3.54223325,2.53886952 C3.94665494,2.29172293 4.32860877,2.17938357 4.71056259,2.17938357 C4.93524131,2.17938357 5.09251641,2.24678719 5.1823879,2.38159442 C5.27225938,2.51640165 5.339663,2.69614462 5.339663,2.96575909 L5.339663,5.54956435 C5.339663,5.66190371 5.38459874,5.70683945 5.4969381,5.70683945 L5.4969381,5.70683945 Z"
              id="path866"
            ></path>
            <path
              d="M17.7419283,8.69506641 C15.2929302,9.72858852 12.6192535,10.2453496 10.1927233,10.2453496 C6.59786383,10.2453496 3.11534369,9.25676321 0.306859702,7.61660856 C0.261923958,7.59414069 0.216988214,7.57167282 0.172052471,7.57167282 C0.0147773676,7.57167282 -0.0750941198,7.75141579 0.0821809832,7.9086909 C2.68845412,10.2678174 6.14850639,11.6832934 9.96804461,11.6832934 C12.709125,11.6832934 15.8770949,10.8295142 18.0564785,9.21182747 C18.4384323,8.942213 18.1238821,8.53779131 17.7419283,8.69506641 Z"
              id="path877"
            ></path>
            <path
              d="M17.6745247,2.56133739 C17.8767355,2.33665867 18.1912857,2.22431931 18.6406431,2.22431931 C18.8653219,2.22431931 19.0900006,2.24678719 19.2922114,2.3141908 C19.359615,2.33665867 19.3820829,2.33665867 19.4270187,2.33665867 C19.4944223,2.33665867 19.539358,2.29172293 19.539358,2.17938357 L19.539358,1.95470485 C19.539358,1.88730124 19.5168901,1.81989762 19.4944223,1.79742975 C19.4719544,1.77496188 19.4270187,1.73002613 19.3820829,1.70755826 C19.0900006,1.64015465 18.7979182,1.57275103 18.5283038,1.57275103 C17.8992034,1.57275103 17.4273781,1.75249401 17.0678921,2.13444783 C16.730874,2.49393378 16.5511311,3.0331627 16.5511311,3.70719886 C16.5511311,4.38123501 16.7084062,4.89799607 17.0454242,5.25748202 C17.3824423,5.61696797 17.8542676,5.79671094 18.483368,5.79671094 C18.8203861,5.79671094 19.1349363,5.7517752 19.3820829,5.63943584 C19.4494865,5.61696797 19.4944223,5.5945001 19.5168901,5.54956435 C19.539358,5.52709648 19.539358,5.45969286 19.539358,5.39228925 L19.539358,5.16761053 C19.539358,5.05527117 19.4944223,5.01033543 19.4270187,5.01033543 C19.4045508,5.01033543 19.359615,5.01033543 19.3146793,5.0328033 C19.0675327,5.10020691 18.8203861,5.14514266 18.5957074,5.14514266 C18.1688178,5.14514266 17.8542676,5.0328033 17.6520568,4.80812458 C17.4498459,4.58344586 17.3599745,4.22395991 17.3599745,3.7521346 L17.3599745,3.63979524 C17.3824423,3.14550206 17.4723138,2.78601611 17.6745247,2.56133739 L17.6745247,2.56133739 Z"
              id="path888"
            ></path>
            <path
              d="M18.6855789,10.8070464 C19.8539082,9.81846001 20.1684584,7.77388367 19.9213118,7.45933346 C19.8089725,7.32452623 19.2697436,7.189719 18.5957074,7.189719 C17.8767355,7.189719 17.0229564,7.3469941 16.3713881,7.79635154 C16.1691772,7.93115877 16.214113,8.11090174 16.4163238,8.08843387 C17.1128279,7.99856239 18.6855789,7.81881941 18.9776612,8.17830536 C19.2472757,8.53779131 18.663111,10.0206709 18.3934966,10.6722391 C18.3260929,10.87445 18.483368,10.9418536 18.6855789,10.8070464 Z"
              id="path899"
            ></path>
            <path
              d="M15.5625447,1.66262252 L15.0907194,1.66262252 C14.97838,1.66262252 14.9334443,1.70755826 14.9334443,1.81989762 L14.9334443,5.54956435 C14.9334443,5.66190371 14.97838,5.70683945 15.0907194,5.70683945 L15.5625447,5.70683945 C15.6748841,5.70683945 15.7198198,5.66190371 15.7198198,5.54956435 L15.7198198,1.81989762 C15.7198198,1.73002613 15.6748841,1.66262252 15.5625447,1.66262252 Z"
              id="path910"
            ></path>
            <path
              d="M15.6973519,0.134807231 C15.6074804,0.0449357437 15.4726732,0 15.3153981,0 C15.158123,0 15.0457836,0.0449357437 14.9559122,0.134807231 C14.8660407,0.224678719 14.8211049,0.337018078 14.8211049,0.471825309 C14.8211049,0.60663254 14.8660407,0.741439772 14.9559122,0.808843387 C15.0457836,0.898714875 15.158123,0.943650618 15.3153981,0.943650618 C15.4726732,0.943650618 15.5850126,0.898714875 15.6748841,0.808843387 C15.7647555,0.7189719 15.8096913,0.60663254 15.8096913,0.471825309 C15.8096913,0.337018078 15.7872234,0.202210847 15.6973519,0.134807231 L15.6973519,0.134807231 Z"
              id="path921"
            ></path>
          </g>
        </g>
      </svg>
    </>
  );
};

