import TextFieldStyled from "@/components/TextFieldStyled";
import {useState} from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import Box from "@mui/material/Box";

export const TextFieldPasswordStyled = ({
                                            name,
                                            label,
                                            hint,
                                            placeholder,
                                            helpertext,
                                            value,
                                            required = false,
                                            onChange
                                        }) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const style = {
        "& .MuiFilledInput-root": {
            "&::before": {
                borderColor: helpertext?.length > 0 ? "#c33132 !important" : "transparent",
            },
        },
        "& .MuiFormHelperText-root": {
            color: helpertext?.length > 0 && "#c33132 !important",
        }
    }

    return (
        <Box sx={style}>
            <TextFieldStyled
                required={required}
                type={showPassword ? 'text' : 'password'}
                label={label}
                hint={hint}
                name={name}
                placeholder={placeholder}
                helpertext={helpertext}
                value={value}
                onChange={onChange}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            tabIndex="-1"
                        >
                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </Box>
    );
};
