import React from "react";

function PrivacyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M10 0l4 4h-4V0zM9 4c0 .563.438 1 1 1h4v9.5a1.5 1.5 0 01-1.5 1.5h-9A1.48 1.48 0 012 14.5v-13A1.5 1.5 0 013.5 0H9v4zm1.5 9c.25 0 .5-.219.5-.5 0-.25-.25-.5-.5-.5h-5c-.281 0-.5.25-.5.5 0 .281.219.5.5.5h5zm0-2c.25 0 .5-.219.5-.5 0-.25-.25-.5-.5-.5h-5c-.281 0-.5.25-.5.5 0 .281.219.5.5.5h5zm.5-2.5c0-.25-.25-.5-.5-.5h-5c-.281 0-.5.25-.5.5 0 .281.219.5.5.5h5c.25 0 .5-.219.5-.5z"
      ></path>
    </svg>
  );
}

export default React.memo(PrivacyIcon);
