import { NavigationDesktop } from "./NavigationDesktop";
import { NavigationMobile } from "./NavigationMobile";

export const Navigation = ({...props}) => {
  return (
    <>
      <nav>
        <NavigationMobile />
        <NavigationDesktop />
      </nav>
    </>
  );
};
