import React from "react";

export const ListmusicIcon = ({...props}) => {
  return (
    <>
      <path
        d="M12.75,24 C15.6515622,24 18,22.3181248 18,20.25 L18,9.15937471 L22.9500003,7.59468699 C23.578125,7.39218807 24,6.8203125 24,6.1640625 L24,1.4981246 C24,1.01765585 23.7703128,0.570000172 23.3812494,0.288843155 C22.9945307,0.00173377991 22.5051556,-0.0720462799 22.0481243,0.0685787201 L16.7981243,1.72701645 C16.1765628,1.92609358 15.75,2.50031233 15.75,3.15656233 L15.75,17.1768751 C14.9006252,16.7491407 13.868906,16.4971876 12.75,16.4971876 C9.84937477,16.4971876 7.5,18.1790628 7.5,20.2471876 C7.5,22.3171878 9.84843779,24 12.75,24 Z M12.375,4.49859381 C12.9960938,4.49859381 13.5,3.99468756 13.5,3.37359381 C13.5,2.75250006 12.9960938,2.24859381 12.375,2.24859381 L1.125,2.24859381 C0.50390625,2.24859381 0,2.75250006 0,3.37359381 C0,3.99468756 0.50390625,4.49859381 1.125,4.49859381 L12.375,4.49859381 Z M18,6.75468779 L18,3.70734358 L21.75,2.52374983 L21.75,5.61093807 L18,6.75468779 Z M12.375,10.4578128 C12.9960938,10.4578128 13.5,9.95390654 13.5,9.33281279 C13.5,8.71171904 12.9960938,8.20781279 12.375,8.20781279 L1.125,8.20781279 C0.50390625,8.20781279 0,8.71125126 0,9.33281279 C0,9.95437503 0.50390625,10.4578128 1.125,10.4578128 L12.375,10.4578128 Z M6.375,16.4578128 C6.99609375,16.4578128 7.5,15.9539065 7.5,15.3328128 C7.5,14.711719 6.99843764,14.2078128 6.375,14.2078128 L1.125,14.2078128 C0.50390625,14.2078128 0,14.711719 0,15.3328128 C0,15.9539065 0.50390625,16.4578128 1.125,16.4578128 L6.375,16.4578128 Z M12.75,21.7078128 C10.8867188,21.7078128 9.75,20.7351565 9.75,20.25 C9.75,19.7226562 10.8867188,18.75 12.75,18.75 C14.6132812,18.75 15.75,19.7226562 15.75,20.25 C15.75,20.7750006 14.6156244,21.7078128 12.75,21.7078128 Z"
      />
    </>
  );
};