import AppContext from "@/context/AppContext";
import theme from "@/styles/theme";
import {Box, Typography} from "@mui/material";
import {useContext, useEffect, useState} from "react";

export const DashboardUserAvatar = ({
                                        dashboardProfile,
                                        dashboardMenu,
                                        displayName,
                                        messenger
                                    }) => {
    const REGEX = /\b(\w)/gisu;
    const {profile} = useContext(AppContext);
    const [chars, setChars] = useState(null);

    useEffect(() => {
        if (!profile || !profile.name || profile.name.length < 2) {
            setChars(null);
            return;
        }
        const name = displayName || profile.name; //"Alex Bangert Julian";
        const matches = name.match(REGEX);

        if (matches.length == 1) {
            setChars(name.substring(0, 2).toUpperCase());
        } else {
            setChars(`${matches[0]}${matches[matches.length - 1]}`.toUpperCase());
        }
    }, [profile]);

    const avatar = {
        "&.avatar": {
            borderWidth: messenger ? "0.09375rem" : "0.125rem",
            borderStyle: "solid",
            borderRadius: "50%",
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            aspectRatio: "1 / 1",
            "&--dashboardprofile": {
                fontSize: messenger ? "1rem" : "4rem",
                borderColor: theme.palette.common.black,
            },
            "&--dashboardmenu": {
                borderColor: "transparent",
                backgroundColor: theme.palette.common.white,
                borderStyle: "none",
                borderWidth: "0",
            },
            "&--messenger": {
                fontSize: messenger ? "1rem" : "4rem",
                borderColor: theme.palette.common.black,
            },
        },
        "& .avatar": {
            "&--initals": {
                fontSize: "70%",
                "&__dashboardmenu": {
                    color: theme.palette.common.black,
                },
            },
        },
    };

    if (!profile) {
        return <></>;
    }

    return (
        <>
            {dashboardProfile && (
                <Box sx={avatar} className="avatar avatar--dashboardprofile">
                    {chars ? (
                        <Typography className="avatar--initals">{chars}</Typography>
                    ) : (
                        <Typography className="avatar--initals">?</Typography>
                    )}
                </Box>
            )}
            {dashboardMenu && (
                <Box sx={avatar} className="avatar avatar--dashboardmenu">
                    {chars ? (
                        <Typography className="avatar--initals avatar--initals__dashboardmenu">
                            {chars}
                        </Typography>
                    ) : (
                        <Typography className="avatar--initals avatar--initals__dashboardmenu">
                            ?
                        </Typography>
                    )}
                </Box>
            )}
            {messenger && (
                <Box sx={avatar} className="avatar avatar--dashboardprofile">
                    {chars ? (
                        <Typography className="avatar--initals">{chars}</Typography>
                    ) : (
                        <Typography className="avatar--initals">?</Typography>
                    )}
                </Box>
            )}
        </>
    );
};
