import { ImageAsset } from "@/components/ImageAsset";
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "next/link";
import theme from "../styles/theme";
import LinkStyled from "./LinkStyled";
import {useContext} from "react";
import AppContext from "@/context/AppContext";

export const BandRegistrationCTA = ({
                                        title = 'Deine Band ist noch nicht angemeldet?',
                                        description = 'Irgendein schöner kompakter “Werbetext” warum es doch eine super Idee wäre sich JETZT SOFORT bei showcase.nrw anzumelden. Es ist immerhin wirklich cool. So noch ein bisschen Text für den Dreizeiler. Geschafft. Danke fürs lesen.',
                                        register_label = 'Jetzt bei showcase.nrw anmelden',
                                        about_label = 'mehr über showcase.nrw erfahren'
                                    }) => {
    const {isAuthenticated} = useContext(AppContext);

  return <>
    <Container
      maxWidth="false"
      sx={{
        py: 8,
        position: "relative",
        [theme.breakpoints.up("sm")]: {
          py: 12,
        },
        color: theme.palette.common.white,
      }}
    >
      <ImageAsset
        url="/img/component-background-band_registration.jpg"
        local={true}
        layout="fill"
      />
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
          }}
        >
            {!isAuthenticated ? (
                <Grid
                    item
                    xs={12}
                    sm={10}
                    sx={{
                        textAlign: "center",
                        position: "relative",
                    }}
                >
                    <Typography variant="h4" align="center">
                        {title}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        align="center"
                        sx={{mt: 2, mb: 4}}
                    >
                        {description}
                    </Typography>
                    <Box
                        sx={{
                            zIndex: "0",
                            position: "relative",
                        }}
                    >
                        <Link href="/register" passHref legacyBehavior>
                            <Button variant="outlined-white-x">
                                <Box sx={{display: {xs: "block", sm: "none"}}}>
                                    Jetzt anmelden
                                </Box>
                                <Box sx={{display: {xs: "none", sm: "block"}}}>
                                    {register_label}
                                </Box>
                            </Button>
                        </Link>
                    </Box>

                    <Typography
                        variant="body2"
                        align="center"
                        sx={{
                            mt: .5,
                            position: "relative",
                            "& .MuiLink-root": {
                                fontSize: ".875rem",
                            },
                        }}
                    >
                        <LinkStyled href="/netzwerk/ueber-uns" label={about_label} />
                    </Typography>
                </Grid>
            ) : (
                <Grid
                    item
                    xs={12}
                    sm={10}
                    sx={{
                        textAlign: "center",
                        position: "relative",
                    }}
                >
                    <Typography variant="h4" align="center">
                        Ist alles auf dem neuesten Stand?
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        align="center"
                        sx={{mt: 2, mb: 4}}
                    >
                        showcase.nrw funktioniert für dich am besten, wenn alle Daten im Profil stimmen und deine Sound-Beispiele dem entsprechen, was du gerade machst. Bitte schau immer mal wieder, ob alles aktuell ist, du keine Nachrichten an dich übersehen hast und alle kommenden Auftritte eingetragen sind. Dann klappt Networking für alle am besten!
                    </Typography>

                </Grid>
            )}
        </Grid>
      </Container>
    </Container>
  </>;
};
