import { IconSquare, IconSquareChecked } from "@/components/icons";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { useEffect, useState } from "react";

export default function CheckboxStyled({
  required = false,
  marginTop = false,
  name,
  label,
  value,
  defaultChecked,
  onChange,
}) {
  const [valueOfField, setValueOfField] = useState(defaultChecked); // we won't break (un-)controlled state
  const [isValid, setIsValid] = useState(!required);

  useEffect(() => {
    if (!required) {
      setIsValid(true);
    }

    setIsValid(valueOfField || false);
  }, []);

  // Check validation on valueOfField changed
  useEffect(() => {
    if (required) {
      setIsValid(valueOfField || false);
    } else {
      setIsValid(true);
    }
  }, [valueOfField]);

  const handleInputChanged = (event) => {
    const v = event.target.value;
    setValueOfField(v === "on");

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <>
      <FormGroup
        sx={{
          marginTop: marginTop ? "0.5rem" : "",
          "& .MuiFormControlLabel-root": {
            alignItems: "flex-start",
          },
          "& .MuiTypography-root": { fontSize: "1rem" },
          marginLeft: ".125rem",
          "& .MuiCheckbox-root": {
            paddingTop: "0",
            paddingBottom: "0",
            transform: "translateY(.125rem)",
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              name={name}
              defaultChecked={defaultChecked}
              checked={value}
              icon={<IconSquare />}
              checkedIcon={<IconSquareChecked />}
              color="secondary"
              required={required}
              onChange={handleInputChanged}
              disableRipple
            />
          }
          label={<span>{label}</span>}
        />
      </FormGroup>
    </>
  );
}
