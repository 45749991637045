import React from "react";

function ImprintIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M14.667 1.778c.722 0 1.333.61 1.333 1.333v.445H0V3.11c0-.722.583-1.333 1.333-1.333h13.334zM0 12.888V4.445h16v8.445c0 .75-.611 1.333-1.333 1.333H1.333C.583 14.222 0 13.64 0 12.89zm10.222-6.666a.457.457 0 00-.444.445c0 .25.194.444.444.444h3.556a.457.457 0 00.444-.444.478.478 0 00-.444-.445h-3.556zm0 1.778a.457.457 0 00-.444.444c0 .25.194.445.444.445h3.556a.457.457 0 00.444-.444.478.478 0 00-.444-.445h-3.556zm0 1.778a.457.457 0 00-.444.444c0 .25.194.445.444.445h3.556a.457.457 0 00.444-.445.478.478 0 00-.444-.444h-3.556zM4.89 6.222c-1 0-1.778.806-1.778 1.778 0 1 .778 1.778 1.778 1.778C5.86 9.778 6.667 9 6.667 8a1.79 1.79 0 00-1.778-1.778zM3.11 10.667c-.75 0-1.333.61-1.333 1.333 0 .25.194.444.444.444h5.334A.457.457 0 008 12c0-.722-.611-1.333-1.333-1.333H3.11z"
      ></path>
    </svg>
  );
}

export default React.memo(ImprintIcon);
