import theme from "@/styles/theme";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmationDialog = ({
  open,
  options,
  onCancel,
  onConfirm,
  onClose,
}) => {
  const {
    title,
    description,
    content,
    confirmationText,
    dialogProps,
    confirmationButtonProps,
    cancellationButtonProps,
    titleProps,
    contentProps,
    allowClose,
    variant = "", // "dark" or empty
  } = options;

  const cancellationText = "Halt Stop! Zurück!";

  return (
    <Dialog
      sx={dialogStyles(variant)}
      fullWidth
      {...dialogProps}
      open={open}
      onClose={allowClose ? onClose : null}
    >
      <IconButton size="large" aria-label="Schließen" onClick={onCancel}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
      {title && (
        <DialogTitle {...titleProps}>
          <Typography
            variant="h4"
            component="p"
            color="common.black"
            sx={{ mb: 1 }}
          >
            {title}
          </Typography>
        </DialogTitle>
      )}
      {content ? (
        <DialogContent {...contentProps}>{content}</DialogContent>
      ) : (
        description && (
          <DialogContent {...contentProps}>
            <Typography variant="body1" color="common.black">
              {description}
            </Typography>
          </DialogContent>
        )
      )}
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              {...confirmationButtonProps}
              onClick={onConfirm}
            >
              {confirmationText}
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant="outlined"
              color="secondary"
              {...cancellationButtonProps}
              onClick={onCancel}
            >
              {cancellationText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

const dialogStyles = (variant) => ({
  "& .MuiPaper-root": {
    padding: "1.5rem",
    backgroundColor:
      variant === "dark"
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
  "& .MuiDialogContent-root": {
    padding: "0 1.5rem",
    overflowY: "hidden",
  },
  "& .MuiTypography-root": {
    textAlign: "center",
    color:
      variant === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
  "& .MuiIconButton-root": {
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(-.25rem, .25rem)",
    color:
      variant === "dark"
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
});

export default ConfirmationDialog;
