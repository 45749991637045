import React from "react";
import {MessageIcon, UsersIcon} from "@/components/icons";
import theme from "@/styles/theme";
import {MenuList} from "@mui/material";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import {useUnreadMessages} from "@/lib/api";
import Badge from "@mui/material/Badge";

export const NavigationDashboardMenuContainer = ({ children, isMobile, ...props }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const {count: countUnseenMessages} = useUnreadMessages();

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const childrenWithEvents = React.Children.map(children, (child) =>
        React.cloneElement(child, {onClick: handleClose})
    );

    const dashboardButton = {
        "& .MuiBadge-badge": {
            transform: "scale(1) translate(0.125rem, -0.0625rem)",
            boxShadow: `0 0 0 0.1875rem ${theme.palette.primary.main}`,
            display: countUnseenMessages !== 0 ? '' : 'none',
        },
        "& .dashboard-button": {
            "&:hover": {
                borderColor: theme.palette.common.white,
                color: theme.palette.common.white,
                "& svg": {
                    fill: theme.palette.common.white,
                },
            },
            "& svg": {
                transition: "all .2s ease-in-out",
                fill: theme.palette.secondary.main,
            },
        },
    }

    return (
        <>
            {isMobile ? (
                <IconButton
                    ref={anchorRef}
                    variant="outlined-secondary"
                    id="dashboard-button"
                    aria-controls={open ? 'dashboard-menu' : undefined}
                    aria-expanded={open ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                >
                    <SvgIcon sx={{ color: theme.palette.common.white, fontSize: "2rem", }}>
                        <UsersIcon />
                    </SvgIcon>
                </IconButton>
            ) : (
                <Badge badgeContent={countUnseenMessages} color="secondary" sx={dashboardButton}>
                    <Button
                        variant="outlined-secondary"
                        ref={anchorRef}
                        id="dashboard-button"
                        aria-controls={open ? 'dashboard-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                        startIcon={<UsersIcon/>}
                        className="dashboard-button"
                    >
                        Dashboard
                    </Button>
                </Badge>
            )}

            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                disablePortal
                sx={{
                    width: isMobile ? "100%" : "auto",
                    marginRight: isMobile ? "-1rem" : "unset",
                }}
            >
                {({TransitionProps, placement}) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom-end" ? "left top" : "left bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="dashboard-menu"
                                    aria-labelledby="dashboard-button"
                                    onClose={handleClose}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    sx={{
                                        padding: "unset",
                                        "& .MuiPaper-root": {
                                            width:  "15.5rem",
                                            marginTop: ".75rem",
                                        },
                                    }}
                                >
                                    {childrenWithEvents}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
