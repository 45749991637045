import React from "react";

export const IconSquareChecked = ({...props}) => {
  return (
    <>
      <svg width="20px" height="20px" viewBox="0 0 20 20">
        <title>icons-square-checked</title>
        <g
          id="icons-square-checked"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <rect
            id="Rectangle"
            fill="#E2E2E2"
            x="0"
            y="0"
            width="20"
            height="20"
          ></rect>
          <rect
            id="Rectangle"
            fill="#76E573"
            x="2"
            y="2"
            width="16"
            height="16"
          ></rect>
          <path
            d="M8.28571429,14.5714286 C8.50535706,14.5714286 8.72499984,14.4883927 8.89174107,14.3201784 L8.89174107,14.3201784 L15.7488839,7.46303558 C15.9162946,7.29589299 16,7.07646397 16,6.85714286 C16,6.36696461 15.6008934,6 15.1428576,6 C14.9232144,6 14.703572,6.08383942 14.5375002,6.25178555 L14.5375002,6.25178555 L8.28571469,12.5035711 L5.46303599,9.6796875 C5.29589299,9.51227679 5.07646438,9.42857143 4.85714286,9.42857143 C4.40044635,9.42857143 4,9.79607146 4,10.2857143 C4,10.5053575 4.08383942,10.7249998 4.25125013,10.8917411 L4.25125013,10.8917411 L7.67982156,14.3203125 C7.84696415,14.4877232 8.06607151,14.5714286 8.28571429,14.5714286 Z"
            id="check"
            fill="#000000"
            fillRule="nonzero"
          ></path>
        </g>
      </svg>
    </>
  );
};