import NextBreadcrumbs from "@/components/breadcrumbs/NextBreadcrumbs";
import { ShowcaseLogo } from "@/components/icons";
import { Navigation } from "@/components/navigation";
import { contentFullscreenState, popboardViewState } from "@/context/states";
import { ListItemIcon, ListItemText } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { ThemeProvider } from "@mui/material/styles";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import MoreInformationDropdown from "ui/components/MoreInformationDropdown";
import AgbIcon from "ui/components/icons/AgbIcon";
import ContactIcon from "ui/components/icons/ContactIcon";
import HomeIcon from "ui/components/icons/HomeIcon";
import ImprintIcon from "ui/components/icons/ImprintIcon";
import PrivacyIcon from "ui/components/icons/PrivacyIcon";
import theme from "../styles/theme";
import { Footer } from "./Footer";
import PlayerBar from "./player/PlayerBar";

export const Page = ({ Component, pageProps }) => {
  const router = useRouter();
  const [contentFullscreen] = useRecoilState(contentFullscreenState);
  const [popboardView, setPopboardView] = useRecoilState(popboardViewState);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (router.query?.popboard === "1") {
      setPopboardView(true);
    }
  }, [router.query?.popboard]);

  return (
    <ThemeProvider theme={theme}>
      {!popboardView && (
        <>
          {!contentFullscreen ? (
            <>
              <Navigation />
              <NextBreadcrumbs />
            </>
          ) : (
            <AppBar
              color="primary"
              position="static"
              sx={{ height: "calc(4rem + 1px)" }}
            >
              <Toolbar disableGutters={true}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ mx: "0.5rem" }}
                >
                  <Grid item xs="auto">
                    <Link href="/">
                      <Button
                        disableRipple
                        sx={{
                          padding: "0",
                          "& span": {
                            height: "3rem !important",
                            width: "6.25rem !important",
                          },
                        }}
                      >
                        <ShowcaseLogo />
                      </Button>
                    </Link>
                  </Grid>
                  <Grid item xs="auto">
                    <MoreInformationDropdown
                      anchorEl={anchorEl}
                      setAnchorEl={setAnchorEl}
                    >
                      <MenuItem onClick={handleClose} component={Link} href="/">
                        <ListItemIcon>
                          <HomeIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ textTransform: "uppercase" }}>
                          showcase.nrw Website
                        </ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        href="/netzwerk/ueber-uns"
                      >
                        <ListItemIcon>
                          <ContactIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ textTransform: "uppercase" }}>
                          Kontakt
                        </ListItemText>
                      </MenuItem>
                      <Divider />
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        href="/legal/impressum"
                      >
                        <ListItemIcon>
                          <ImprintIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText sx={{ textTransform: "uppercase" }}>
                          Impressum
                        </ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        href="/legal/datenschutz"
                      >
                        <ListItemIcon>
                          <PrivacyIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ textTransform: "uppercase" }}>
                          Datenschutzerklärung
                        </ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={handleClose}
                        component={Link}
                        href="/legal/agb"
                      >
                        <ListItemIcon>
                          <AgbIcon />
                        </ListItemIcon>
                        <ListItemText sx={{ textTransform: "uppercase" }}>
                          AGB
                        </ListItemText>
                      </MenuItem>
                    </MoreInformationDropdown>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
          )}
        </>
      )}
      <Component {...pageProps} />

      {!popboardView && (
        <>
          <PlayerBar />
          <Footer />
        </>
      )}
    </ThemeProvider>
  );
};
