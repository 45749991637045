export const responsiveImage = (formats, expectedWidth) => {
  if (!formats) {
    return null;
  }
  const format = getFormat(formats, expectedWidth);

  return format.url;
};

export const responsiveImageHeight = (formats, expectedWidth) => {
  if (!formats) {
    return null;
  }
  const format = getFormat(formats, expectedWidth);

  const fac = format.width / expectedWidth;

  return format.height / fac;
};

const getFormat = (formats, expectedWidth) => {
  if (expectedWidth >= 1000) {
    return (
      formats.xlarge ||
      formats.large ||
      formats.medium ||
      formats.small ||
      formats.xsmall ||
      formats.thumbnail
    );
  }

  if (expectedWidth >= 750) {
    return (
      formats.large ||
      formats.medium ||
      formats.small ||
      formats.xsmall ||
      formats.thumbnail
    );
  }

  if (expectedWidth >= 500) {
    return formats.small || formats.xsmall || formats.thumbnail;
  }

  return formats.thumbnail;
};

export const guessStrapiImageProps = (
  strapiImageObject,
  expectedWidth,
  height,
) => {
  let url = strapiImageObject.url;
  let width = expectedWidth;
  let calculatedHeight = height;
  if (strapiImageObject.formats) {
    url = getFormat(strapiImageObject.formats, expectedWidth * 2).url;
    calculatedHeight = responsiveImageHeight(
      strapiImageObject.formats,
      expectedWidth,
    );
  }

  return {
    url,
    width: width,
    height: calculatedHeight,
  };
};

export const nl2br = (str, is_xhtml) => {
  if (typeof str === "undefined" || str === null) {
    return "";
  }
  const breakTag =
    is_xhtml || typeof is_xhtml === "undefined" ? "<br />" : "<br>";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2",
  );
};

export const strapiTimeFrag = (timeFromStrapi) => {
  const regex = /^([0-9]{2}:[0-9]{2})/;

  return timeFromStrapi.match(regex)[0];
};

export const distanceFilter = (point1, point2, radius) => {
  if (!radius) {
    return true;
  }

  const radPoint1Lat = (Math.PI * point1[0]) / 100;
  const radPoint2Lat = (Math.PI * point2[0]) / 100;
  const theta = point1[1] - point2[1];
  const radTheta = (Math.PI * theta) / 100;
  let dist =
    Math.sin(radPoint1Lat) * Math.sin(radPoint2Lat) +
    Math.cos(radPoint1Lat) * Math.cos(radPoint2Lat) * Math.cos(radTheta);
  if (dist > 1) {
    dist = 1;
  }

  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;

  dist = dist * 1.609344;

  return dist < radius;
};

export const today = () => {
  return new Date().toISOString().slice(0, -5);
};

export const buildSongSearchQuery = (filterConfig) => {
  const queryFilter = !filterConfig.query ? "" : `&query=${filterConfig.query}`;
  const regionFilter = !filterConfig.region
    ? ""
    : `&regionId=${filterConfig.region?.id ?? filterConfig.region}`;
  const genreFilter = !filterConfig.genre
    ? ""
    : `&genreId=${filterConfig.genre?.id ?? filterConfig.genre}`;
  const languageFilter = !filterConfig.language
    ? ""
    : `&language=${filterConfig.language}`;
  const surroundFilter =
    !filterConfig.radius || !filterConfig.zipcode
      ? ""
      : `&zipcode=${filterConfig.zipcode.zipcode}&radius=${filterConfig.radius}`;
  const genderFilter =
    !filterConfig.gender || filterConfig.gender === "not_specified"
      ? ""
      : `&gender=${filterConfig.gender}`;
  const minMemberFilter = !filterConfig.members_min
    ? ""
    : `&members_min=${filterConfig.members_min}`;
  const maxMemberFilter = !filterConfig.members_max
    ? ""
    : `&members_max=${filterConfig.members_max}`;
  const minAgeFilter = !filterConfig.age_min
    ? ""
    : `&age_min=${filterConfig.age_min}`;
  const maxAgeFilter = !filterConfig.age_max
    ? ""
    : `&age_max=${filterConfig.age_max}`;

  const sort = !filterConfig._sort ? "" : `&_sort=${filterConfig._sort}`;

  const allFilters = `${queryFilter}${regionFilter}${genreFilter}${languageFilter}${genderFilter}${minMemberFilter}${maxMemberFilter}${minAgeFilter}${maxAgeFilter}${surroundFilter}${sort}`;

  return allFilters;
};

export const generateRandomString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

export const selectRandomArrayElements = (array, numElements) => {
  const shuffled = array.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, numElements);
};

export const countUniqueBandsInPlayerPlaylist = (playlist) => {
  const bandIds = playlist.map((song) => song.band.id ?? song.band);
  return new Set(bandIds).size;
};

export const darkenColor = (color, percent) => {
  if (!color) return null;

  const rgb = color.split(",").map((c) => parseInt(c.trim()));
  const darkenedRgb = rgb.map((c) => Math.round(c * (1 - percent / 100)));

  return `${darkenedRgb.join(",")}`;
};

export const generatePlayerIframeCode = (playlistType, playlistId) => {
  const height = playlistType === "song" ? '"287"' : '"500"';

  return `<iframe title="showcase.nrw Player"
      src="https://www.showcase.nrw/player/embed/${playlistType}/${playlistId}"
      width="100%" 
      height=${height}
      frameBorder="0" 
      allow="autoplay; fullscreen">
</iframe>`;
};

export const calcAge = (yearOfBirth) => {
  const today = new Date();
  const age = today.getFullYear() - yearOfBirth;
  return age;
};

export default {
  responsiveImage,
  responsiveImageHeight,
  strapiTimeFrag,
  guessStrapiImageProps,
  distanceFilter,
};
