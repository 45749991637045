import React from "react";

function ContactIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M10.4 8.4c0 .9-.725 1.6-1.6 1.6l-3.95.025-2.075 1.55c-.15.1-.375 0-.375-.2v-1.35L1.6 10C.7 10 0 9.3 0 8.4V3.6C0 2.725.7 2 1.6 2h7.2c.875 0 1.6.725 1.6 1.6v4.8zm4-3.2c.875 0 1.6.725 1.6 1.6v4.825c0 .875-.725 1.575-1.6 1.575h-.8v1.375c0 .2-.25.3-.425.175L11.1 13.2H7.975c-.875 0-1.575-.7-1.575-1.6v-.8h2.4c1.3 0 2.4-1.075 2.4-2.4V5.2h3.2z"
      ></path>
    </svg>
  );
}

export default React.memo(ContactIcon);
