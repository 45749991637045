import React from "react";

export const TiktokIcon = ({...props}) => {
  return (
    <>
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <path d="M6.17208731,21.1388677 C10.403331,23.5838495 15.694628,20.5315073 15.6964229,15.644652 L15.6964229,15.644652 L15.6964229,8.66493883 C17.0945163,9.66571231 18.7717102,10.2019168 20.4910704,10.1978044 L20.4910704,10.1978044 L20.4910704,6.7852744 C19.5605905,6.78546986 18.6506125,6.51193285 17.8744591,5.99872946 C16.7718812,5.28819827 16.0104207,4.1551179 15.769062,2.8658255 C15.7183508,2.58005435 15.694035,2.29022502 15.6964223,2 L15.6964223,2 L12.2596796,2 L12.2596796,15.644652 C12.2596981,16.4610356 11.9172769,17.2399945 11.3157529,17.7919467 C9.66281512,19.3086654 6.98783329,18.4672569 6.50078521,16.2774127 C6.01373714,14.0875672 8.07990933,12.1916689 10.2198946,12.8647956 L10.2198946,12.8647956 L10.2198946,9.35423993 C8.82171712,9.16041851 7.39882053,9.43811858 6.17612523,10.1434433 C1.94308667,12.5853162 1.94084365,18.6938859 6.17208731,21.1388677 Z"></path>
      </svg>
    </>
  );
};

