import AppContext from "@/context/AppContext";
import { contentFullscreenState } from "@/context/states";
import { useFooter } from "@/lib/api";
import { Button, Link } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { useRecoilState } from "recoil";
import theme from "../styles/theme";
import { BandRegistrationCTA } from "./BandRegistrationCTA";
import LinkStyled from "./LinkStyled";
import { StrapiImageAsset } from "./StrapiImageAsset";

export const Footer = ({}) => {
  const { footer, isLoading } = useFooter();
  const { setDisplayCookieConsent } = useContext(AppContext);
  const [contentFullscreen] = useRecoilState(contentFullscreenState);

  if (isLoading) {
    return (
      <footer>
        <BandRegistrationCTA />
      </footer>
    );
  }

  return (
    <>
      {!contentFullscreen && (
        <footer>
          <BandRegistrationCTA
            title={footer?.cta_title}
            description={footer?.cta_description}
            register_label={footer?.cta_register_label}
            about_label={footer?.cta_about_label}
          />
          <Container
            maxWidth="false"
            sx={{
              bgcolor: theme.palette.secondary.main,
              py: 2,
            }}
          >
            <Container maxWidth="xl">
              <Grid container justifyContent="center">
                <Grid item xs={12} md={10}>
                  <Grid container spacing={2} justifyContent="center">
                    {footer?.logos?.map((logo, index) => {
                      return (
                        <Grid item xs key={index}>
                          <Link
                            href={logo.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <StrapiImageAsset
                              strapiImageObject={logo.image}
                              layout="responsive"
                              objectFit="contain"
                              width={160}
                              height={90}
                              fixedHeight={true}
                            />
                          </Link>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Container>
          <Container
            maxWidth="false"
            sx={{
              bgcolor: theme.palette.secondary.dark,
              py: 2,
            }}
          >
            <Grid
              container
              justifyContent="center"
              alignItems="baseline"
              sx={{
                position: "relative",
                textTransform: "uppercase",
                color: theme.palette.common.white,
                "& .MuiTypography-root, & .footerText": {
                  padding: 0,
                  fontSize: "0.625rem",
                  lineHeight: "0.625rem",
                  "&.divider": {
                    margin: "0 0.25rem",
                  },
                },
                [theme.breakpoints.up("sm")]: {
                  "& .MuiTypography-root, & .footerText": {
                    fontSize: "0.875rem !important",
                  },
                },
                "& .footerText": {
                  color: theme.palette.common.white,
                },
              }}
            >
              <Grid item xs={12} md="auto">
                <Typography textAlign="center">
                  All rights reserved by showcase.nrw
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Typography
                  component="span"
                  className="divider"
                  sx={{ display: { xs: "none", md: "inline" } }}
                >
                  /
                </Typography>
                <LinkStyled href="/legal/agb" label="AGB" variant="white" />
                <Typography component="span" className="divider">
                  /
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <LinkStyled
                  href="/legal/datenschutz"
                  label="Datenschutzerklärung"
                  variant="white"
                />
                <Typography component="span" className="divider">
                  /
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <Button
                  disableRipple
                  className="footerText"
                  onClick={() => {
                    setDisplayCookieConsent(true);
                  }}
                >
                  Cookie Einstellungen bearbeiten
                </Button>
                <Typography component="span" className="divider">
                  /
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <LinkStyled
                  href="/legal/impressum"
                  label="Impressum"
                  variant="white"
                />
              </Grid>
            </Grid>
          </Container>
        </footer>
      )}
    </>
  );
};
