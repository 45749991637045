import React from "react";
import NextLink from "next/link";
import Link from "@mui/material/Link";
import DownloadIcon from "@mui/icons-material/Download";

export default function LinkStyled({variant, href, label, icon, ...props}) {
  return (
    <NextLink href={href} passHref legacyBehavior>
      <Link variant={variant}>
        {icon && <DownloadIcon fontSize="small" sx={{ marginRight: ".25rem", marginBottom: "-.25rem" }} />}
        {label}
      </Link>
    </NextLink>
  );
}