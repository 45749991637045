import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { grey } from "@mui/material/colors";
import LinkStyled from "@/components/LinkStyled";
import { useRouter } from "next/router";
import { useMemo } from "react";
import { Breadcrumbs } from "@mui/material";
import theme from "@/styles/theme";

export default function NextBreadcrumbs() {
  const router = useRouter();

  const PAGETITLES = {
    'showcase-map': 'Showcase Map',
    'ueber-uns': 'Über uns',
    createmusic: 'Create Music NRW',
    newheimatsounds: 'New.Heimat.Sounds',
    foerderung: 'Förderung',
    foerderfinder: 'Förderfinder',
    secure: 'Dashboard',
    settings: 'Einstellungen',
    chat: 'Messenger',
    board: 'Schwarzes Brett',
    edit: 'Bearbeiten',
    create: 'Erstellen',
  }

  const breadcrumbs = useMemo(() => {
    const parts = generatePathParts(router.asPath);
    const pathNames = generatePathParts(router.pathname);

    const crumbs = parts.map((subpath, idx) => {
      const param = pathNames[idx];
      const href = '/' + parts.slice(0, idx+1).join("/");

      return {
        href,
        name: PAGETITLES[subpath] ?? subpath
      }
    });

    return [{href: '/', name: 'Start' }, ...crumbs];
  }, [router.asPath, router.pathname]);

  if (router.pathname === "/") {
    return <></>;
  }

  return (<>
    <Container
      maxWidth="false"
      disableGutters={true}
      sx={style}
    >
      <Container maxWidth="xl">
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={10}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={
                <ArrowForwardIcon
                  sx={{ fontSize: 16, color: grey[400], mt: ".125rem" }}
                />
              }
              sx={{
                "& .MuiLink-root": {
                  fontSize: ".875rem",
                  textTransform: "uppercase"
                }
              }}
            >
              {breadcrumbs.map((crumb, idx) => {
                return <LinkStyled key={idx} href={crumb.href} label={crumb.name} variant="white" />
              })}

            </Breadcrumbs>
          </Grid>
        </Grid>
      </Container>
    </Container>
    <Container
      maxWidth="false"
      disableGutters={true}
      sx={{
        background: "#d4882a",
        paddingY: "1.259375rem",
        zIndex: "500"
      }}
    >
    </Container>
  </>);
}

const generatePathParts = (path) => {
  const pathWithoutQuery = path.split("?")[0];

  return pathWithoutQuery.split("/").filter(p => p.length > 0);
};

const style =  {
  background: "#d4882a",
  paddingY: 1,
  position: "fixed",
  zIndex: "1000",

  "& .MuiBreadcrumbs-ol": {
    "& .MuiBreadcrumbs-li": {
      "&:last-of-type": {
        "& .MuiTypography-root": {
          color: theme.palette.common.black,
        },
      },
    },
  },

}
