import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Grid } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fade from "@mui/material/Fade";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import React from "react";
import { useRouter } from "next/router";

export const NavigationDesktopMenu = ({ title, children }) => {
  const anchorRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const router = useRouter();

  const timeoutLength = 300;

  const handleToggle = () => {
    timeoutLength;
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const childrenWithEvents = React.Children.map(children, (child) =>
    React.cloneElement(child, { onClick: handleClose })
  );

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  return (
    <Grid item xs="auto">
      <Button
        ref={anchorRef}
        id={`composition-${title}-button`}
        aria-controls={open ? `composition-${title}-menu` : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onMouseEnter={handleToggle}
        onMouseLeave={handleToggle}
        color="inherit"
        variant={
          router.pathname
            .toLowerCase()
            .replace("oe", "ö")
            .indexOf(`/${title.toLowerCase()}`) === 0
            ? "navbutton__is-active"
            : ""
        }
        disableRipple
        startIcon={<KeyboardArrowDownIcon />}
        sx={{ height: "4.5rem" }}
      >
        {title}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              marginTop: "-.75rem",
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper onMouseEnter={handleToggle} onMouseLeave={handleToggle}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id={`composition-${title}-menu`}
                  aria-labelledby={`composition-${title}-button`}
                  onKeyDown={handleListKeyDown}
                >
                  {childrenWithEvents}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Grid>
  );
};
