export const EditIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path d="M23.203 2.344a2.563 2.563 0 01-.047 3.703L12.984 16.219a2.88 2.88 0 01-1.218.703C10.172 17.297 6.703 18 6.703 18H6.61c-.375 0-.703-.328-.609-.75l1.125-5.063c.094-.468.328-.937.703-1.265L17.953.797A2.628 2.628 0 0119.828 0c.656 0 1.313.281 1.828.797l1.547 1.547zm-1.594 2.11a.368.368 0 000-.516L20.062 2.39c-.093-.094-.187-.141-.234-.141-.094 0-.187.047-.281.14L9.422 12.517c-.047.046-.094.093-.094.187l-.61 2.578c.938-.187 1.923-.422 2.579-.562.047 0 .094-.047.14-.094L21.61 4.453zM19.875 13.5c.61 0 1.125.516 1.125 1.125l-.14 6C20.86 22.5 19.36 24 17.53 24H3.328C1.5 24 0 22.5 0 20.625V6.375C0 4.547 1.5 3 3.328 3h6.797c.61 0 1.125.516 1.125 1.125a1.11 1.11 0 01-1.125 1.125h-6.75A1.11 1.11 0 002.25 6.375v14.25c0 .656.469 1.125 1.125 1.125h14.25a1.11 1.11 0 001.125-1.125v-6c0-.61.469-1.125 1.125-1.125z"></path>
    </svg>
  );
};
