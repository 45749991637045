import React from "react";

function ArrowDropDownIcon() {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-1om0hkc"
      data-testid="ArrowDropDownIcon"
      viewBox="0 0 24 24"
    >
      <path d="M7 10l5 5 5-5z"></path>
    </svg>
  );
}

export default React.memo(ArrowDropDownIcon);
