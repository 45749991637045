import React from "react";

export const ClubIcon = ({...props}) => {
  return (
    <>
      <svg viewBox="0 0 24 24">
        <g>
          <path
            d="M8.40000057,11.6 C11.051251,11.6 13.2000006,9.45087509 13.2000006,6.8 C13.2000006,4.14912491 11.051251,2 8.40000057,2 C5.74875069,2 3.60000057,4.14912491 3.60000057,6.8 C3.60000057,9.45087509 5.74875069,11.6 8.40000057,11.6 Z"
          ></path>
          <path
            d="M16.2,11.6 C18.5212498,11.6 20.4,9.72124977 20.4,7.4 C20.4,5.07875023 18.5212498,3.2 16.2,3.2 C15.2583755,3.2 14.3984997,3.52081223 13.698,4.04412537 C14.1299995,4.87362518 14.4,5.80249977 14.4,6.8 C14.4,8.13200073 13.9526253,9.35524998 13.2153751,10.3516247 C13.9762505,11.1199999 15.0299995,11.6 16.2,11.6 Z"
          ></path>
          <path
            d="M15.4987495,21.2 C16.21875,21.2 16.8,20.61875 16.8,19.8987495 C16.8,16.3100002 13.8899998,13.4 10.3012505,13.4 L6.49875069,13.4 C2.90887527,13.4 0,16.3100002 0,19.8987495 C0,20.61875 0.58200016,21.2 1.29975014,21.2 L15.4987495,21.2 Z"
          ></path>
          <path
            d="M22.8,21.2 C23.4637505,21.2 24,20.6600002 24,19.9662502 C24,16.6850002 21.3150009,14 17.9662502,14 L15.196875,14 C16.9200005,15.4137505 18,17.5287502 18,19.8987495 C18,20.3787502 17.8575005,20.8212498 17.625,21.2 L22.8,21.2 Z"
          ></path>
        </g>
      </svg>
    </>
  );
};