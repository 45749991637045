import { guessStrapiImageProps } from "@/lib/helper";
import Image from "next/legacy/image";
import { useEffect, useState } from "react";

export const StrapiImageAsset = ({ strapiImageObject, width, height, priority = false, fixedHeight = false, alt, objectFit = "cover", objectPosition = "initial", placeholder = null, blurDataURL = "LCC%2Jt200V~?bt9RoRi00WG~qxv", ...props }) => {
    const [ imageProps, setImageProps ] = useState(null);

    useEffect(() => {
        if (strapiImageObject) {
            const props = guessStrapiImageProps(strapiImageObject, width, height);
            if (fixedHeight) {
                props.height = height;
            }
            setImageProps(props);
        }

    }, [strapiImageObject]);

    const layoutProp = props.layout || "intrinsic";

    if (!imageProps || !imageProps.url || (!strapiImageObject && !strapiImageObject.formats && !strapiImageObject.url)) return <></>;

    return (
      <Image
        src={imageProps.url}
        width={imageProps.width}
        height={imageProps.height}
        objectFit={objectFit}
        objectPosition={objectPosition}
        layout={layoutProp}
        placeholder={placeholder}
        blurDataURL={blurDataURL}
        quality={78}
        priority={priority}
        alt={alt}
      />
    );
};
